import React, { useEffect, useRef, useState } from 'react';
import * as constants from '../js/constants'
import  '../css/main.css'
import { io } from 'socket.io-client';
import * as axiosClient from '../js/Axios';
import SocketContext from './SocketContext'

const chat_notification_styles = {
    notification: {
        position: 'fixed',
        top: '10px',
        right: '10px',
        padding: '15px',
        borderRadius: '8px',
        backgroundColor: '#e6eaeb',
        border: '1px solid #D3D3D3',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        maxWidth: '250px',
        textAlign: 'center',
        zIndex: '1000000 !important',
        width:'250px'
    },
    notification_p: {
      color:'black',
      fontWeight: '600',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    },
    acceptButton: {
        backgroundColor: 'lightgreen',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight:'600',
    },
    rejectButton: {
        backgroundColor: 'lightcoral',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight:'600',
    },
};



function SocketMain(props){
	const [showChatNotification, setShowChatNotification] = useState(false)
	const [newConversationId, setNewConversationId] = useState(-1)
	const [isShaking, setIsShaking] = useState(false);
	const [botificationMessage, setNotificationMessage] = useState(null)
	var globalSocket = null;
	let socketConnected = true;
	useEffect(()=>{
		if(socketConnected){
			socketConnected = false;
			connectToSocket()
		}		
	},[])	
	const handleTimeout = ()=>{

	}
	const handleChatAccept = () => {
	  axiosClient.postRequest(constants.CHAT_URL+"accept_chat?id="+newConversationId)
	          .then(function (response) {
	        	window.location.href='/chats'       
	  }).catch(function (response) {
	              console.log(response.data)
	  });
	  
	};

	const handleChatReject = () => {
	    setShowChatNotification(false);
	};
	function connectToSocket(){
		const URL = process.env.REACT_APP_NODE_ENV === 'production' 
				  ? 'wss://api.supportspoc.com' 
				  : process.env.REACT_APP_NODE_ENV === 'staging' 
				  ? 'wss://api.support-spoc.com' 
				  : 'ws://localhost';
		let token = localStorage.getItem('id_token');
		// const socket = new io(auth: { 'token':  token})
		const socket = io(URL, {auth: (cb) => {
							    cb({
							      token: token
							    });
							  }
							})
		.connect(URL, {
		    'reconnection': true,
		    'reconnectionDelay': 1000,
		    'reconnectionDelayMax' : 5000,
		    'reconnectionAttempts': 5000
		});
		globalSocket = socket
		socket.on('connect', onConnect);
	    socket.on('disconnect', onDisconnect);
	    socket.on('message', onMessage);

		function onMessage(message){
			 message = typeof message === 'string' ? JSON.parse(message) : message;
		    if(message.type === 'welcome'){
		        localStorage.setItem('chat_token', message.token)
		    }else if(message.type === 'unable_to_connect_to_chat'){		    	
		    }else if(message.type === 'call_ended'){
		    	if(props.socketMainCallbackToDialpad)
		    		props.socketMainCallbackToDialpad(message)
		    }else if(message.type ==='user_status_change'){
		    	if(props.callbackFromTopnav)
		    		props.callbackFromTopnav(message)
		    }
		    else if(message.type === 'customer_accepted_call'){		  
		    	if(props.socketMainCallbackToDialpad)
		    		props.socketMainCallbackToDialpad(message)
		    }
		    else if(message.type === 'call_cancelled'){
		    	if(props.notifyUserCall)
		    		props.notifyUserCall(message)
		    }
		    else if(message.type === 'call_ended'){
		    	if(props.socketMainCallbackToDialpad)
		    		props.socketMainCallbackToDialpad(message)
		    }
		    else if(message.type === 'notify_user_call'||
		    		message.type === 'warm_transfer' ||
		    		message.type === 'cold_transfer'){		    	
		    	if(props.notifyUserCall)
		    		props.notifyUserCall(message)
		    	var message = {"type": "received_notification", "message": message}
		    	socket.send(message) //TODO implement this	    	
		    }else if(message.type === 'chat_initiated'){
		    	if (window.location.pathname === '/chats') 
      				return
			    setNewConversationId(message.newConversationId)  
			    setShowChatNotification(true)
			    setTimeout(handleTimeout, 30000)   
			 }
		}
		function onConnect(){
			console.log('onConnect')		    
			let session = JSON.parse(sessionStorage.getItem('ss_sess'))
			if(session == null || session.userId == null)
				return setTimeout(onConnect, 1000)
			var message = {"type": "user_connected", "room" : session.userId }
			socket.send(message)			
		}

	    function onDisconnect(){console.log("onDisconnect")}
		            // inputTextRef.current.disabled = false
	}
		
	return(
	  		<div>
	  			  { showChatNotification &&
		              (
		                  <div style={chat_notification_styles.notification} className={isShaking ? 'shake' : ''} >
		                      <p style={chat_notification_styles.notification_p}>
		                          {botificationMessage || 'New Chat Request'}
		                      </p>
		                      <div style={chat_notification_styles.buttonContainer}>
		                          <button 
		                              style={chat_notification_styles.acceptButton} 
		                              onClick={handleChatAccept}>
		                              Accept
		                          </button>
		                          <button 
		                              style={chat_notification_styles.rejectButton} 
		                              onClick={handleChatReject}>
		                              Reject
		                          </button>
		                      </div>
		                  </div>
		              )
		          }
	 		</div>
	 	)
}
export default SocketMain;