  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import Attachment from '../../Ticket/js/Attachment'
  import ProfilePicture from  '../../ProfilePicture/ProfilePicture'

  function ChatUserMessage(props){
    console.log('ChatUserMessage '+props.agentImageUrl)
    const renderChatAttachments = () => {    
        return (
          <div>
            {props.attachments && props.attachments.map((attachment, index) => (              
              <Attachment name={attachment.name} 
                          isEditing={false}
                          index = {index}
                          size={attachment.size}                          
                          source={'create'}
                          attachment_id={attachment.id}/>
            ))}
          </div>
        );

    }

      return(
             <div class="chat-header-row" >
               <div class="chat-header-img">
                  {props.agentImageUrl !== 'null' ? (
                          <img src={props.agentImageUrl} alt="Profile"  class='chat-header-img'/>
                        ) : (
                          <ProfilePicture name={props.contact ? props.contact : 'U'} classProp='chat-header-img'/>
                        )
                  } 
               </div>
               <div class="chat-header-info">
                 <div class="main-header" style={{flexDirection: 'column',  alignItems:'flex-start'}}>
                   <p style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>{props.text ? decodeURIComponent(props.text.replace( 'ss_new_line', '\n')).replace('ss_quote', "\"") : ''}</p>
                   {renderChatAttachments()}
                 </div>
               </div>               
             </div>    
    
          )

  }

  export default ChatUserMessage;