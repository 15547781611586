  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
import ProfilePicture from  '../../ProfilePicture/ProfilePicture'

  function ChatCustomerMessage(props){
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)      

      return(
             <div class="chat-header-row ChatMyMessage">     
                 <div class="chat-header-info">
                   <div class="main-header">
                     <p style={{
    whiteSpace: 'pre-wrap',  // Preserve newlines and spaces
    wordWrap: 'break-word',  // Break long words
    overflowWrap: 'break-word',  // Modern equivalent of wordWrap
  }}>{ props.text ? decodeURIComponent(props.text.replace( 'ss_new_line', '\n')).replace('ss_quote', "\"")  : ''}</p>       
                   </div>
                 </div>
                 <div className={'chat-header-img'} style={{marginLeft:'10px'}}>
                  {props.contactImage !== 'null' ? (
                          <img src={props.contactImage} alt="Profile"  class='chat-header-img'/>
                        ) : (
                          <ProfilePicture name={props.name ? props.name : 'U'} classProp='chat-header-img'/>
                        )
                  } 
                 </div>
            </div>     
            
      
          )

  }

  export default ChatCustomerMessage;