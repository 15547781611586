

import { useCallback, useEffect, useRef, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Underline from "../../../img/underline.svg";
import Bold from "../../../img/bold.svg";
import Italic from "../../../img/italic.svg";
import OL from '../../../img/list-ordered.svg'
import UL from '../../../img/list-unordered.svg'
import Image from '../../../img/image.svg'
import URL from '../../../img/link.svg'
import JLeft from '../../../img/align-left.svg'
import JRight from '../../../img/align-right.svg'
import JCenter from '../../../img/align-center.svg'
import EditorToolbar, { modules, formats } from "./QuillToolbar";
import Attachment from '../../Ticket/js/Attachment'
import {ToastContainer, toast } from 'react-toastify';

const MyQuill = (props) => {
  const editor = document.getElementById('editor');
  const [attachmentFilesList, setAttachmentFilesList] = useState([])
  let contextMenu;
  let selectedTable = null
  const  selectedCell = useRef(null)
    useEffect(()=>{
        contextMenu = document.getElementById('contextMenu')
        const windowHeight = window.innerHeight;
        const sixtyPercentHeight = windowHeight * 0.6;
        props.descriptionRef.current.style.height=sixtyPercentHeight+'px'
        props.descriptionRef.current.style.maxHeight=sixtyPercentHeight+'px'
    },[])

  let currentImage = null;
  let currentTable = null;
  let currentResizer = null;
  let startX, startY, startWidth, startHeight;
  const historyStack = [];
  const redoStack = [];

function getHeightFromDivToScreen(divId) {
    const div = document.getElementById(divId);
    if (!div) {
        console.error("Div not found");
        return 0;
    }

    const divTop = div.getBoundingClientRect().top; // Distance from the top of the viewport to the top of the div
    const windowHeight = window.innerHeight; // Height of the viewport
    const heightFromDivToEnd = windowHeight - divTop; // Calculate remaining height from the div's top to the bottom of the viewport

    return heightFromDivToEnd; // Return the height in pixels
}



function applyHeaderStyle(event) {
  const selection = window.getSelection();
  if (!selection.rangeCount) return; // No selection made

  const range = selection.getRangeAt(0);
  const container = range.commonAncestorContainer;

  // Check if the selected content is already inside a header tag
  let headerElement = container;
  while (headerElement && !(headerElement instanceof HTMLElement)) {
    headerElement = headerElement.parentNode; // Traverse upwards to find the enclosing element
  }

  if (headerElement && /^(H[1-6])$/i.test(headerElement.tagName)) {
    // If a header exists, unwrap its content
    const parent = headerElement.parentNode;
    while (headerElement.firstChild) {
      parent.insertBefore(headerElement.firstChild, headerElement);
    }
    parent.removeChild(headerElement); // Remove the original header element
  }

  if (event.target.value) {
    // Apply the new header tag
    const newHeader = document.createElement(event.target.value);
    range.surroundContents(newHeader); // Wrap the selected content with the new header
  } else {
    // Reset to a paragraph if no header is selected
    const paragraph = document.createElement("p");
    paragraph.appendChild(range.extractContents());
    range.insertNode(paragraph);
  }

  // Move the cursor after the inserted element
  range.collapse(false);
  selection.removeAllRanges();
  selection.addRange(range);
}



// Save the current state of the editor
function saveHistory() {
  const editor = document.querySelector('#ticket-create-editor');
  if (!editor) return;

  const state = {
    html: editor.innerHTML,
    selection: saveSelection(editor),
  };
  historyStack.push(state);
  redoStack.length = 0; // Clear redo stack on new action
}

// Restore a previous state
function restoreHistory(state) {
  const editor = document.querySelector('#ticket-create-editor');
  if (!editor || !state) return;

  editor.innerHTML = state.html;
  restoreSelection(editor, state.selection);
}

// Save selection range
function saveSelection(container) {
  const selection = window.getSelection();
  if (selection.rangeCount === 0) return null;

  const range = selection.getRangeAt(0);
  const preRange = range.cloneRange();
  preRange.selectNodeContents(container);
  preRange.setEnd(range.startContainer, range.startOffset);
  const start = preRange.toString().length;

  return {
    start,
    end: start + range.toString().length,
  };
}

// Restore selection range
function restoreSelection(container, savedSelection) {
  if (!savedSelection) return;

  const charIndex = { start: savedSelection.start, end: savedSelection.end };
  const range = document.createRange();
  const selection = window.getSelection();
  let node = container;
  let startNode = null,
    endNode = null;
  let totalChars = 0;

  function traverseNodes(node) {
    if (node.nodeType === 3) {
      const nodeLength = node.textContent.length;
      if (!startNode && totalChars + nodeLength >= charIndex.start) {
        startNode = { node, offset: charIndex.start - totalChars };
      }
      if (!endNode && totalChars + nodeLength >= charIndex.end) {
        endNode = { node, offset: charIndex.end - totalChars };
      }
      totalChars += nodeLength;
    } else {
      for (let child of node.childNodes) traverseNodes(child);
    }
  }

  traverseNodes(container);

  if (startNode && endNode) {
    range.setStart(startNode.node, startNode.offset);
    range.setEnd(endNode.node, endNode.offset);
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

// Undo function
function undo() {
  if (historyStack.length > 1) {
    const currentState = historyStack.pop();
    redoStack.push(currentState);
    restoreHistory(historyStack[historyStack.length - 1]);
  }
}

// Redo function
function redo() {
  if (redoStack.length > 0) {
    const nextState = redoStack.pop();
    historyStack.push(nextState);
    restoreHistory(nextState);
  }
}
function execCmd(command, value = null) {
    document.execCommand(command, false, value);
  }


  // Insert a video from a URL
  function insertVideo() {
    const url = prompt('Enter video URL:');
    if (url) {
      const videoTag = `<iframe src="${url}" frameborder="0" allowfullscreen></iframe>`;
      execCmd('insertHTML', videoTag);
    }
  }

  // Insert image into the editor
  function uploadImage(event) {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = function(e) {
      const imgTag = `<img src="${e.target.result}" class="resizable" style="max-width: 100%;" />`;
      execCmd('insertHTML', imgTag);
    };
    reader.readAsDataURL(file);
  }

  // Show context menu for table options
  function showTableMenu(event) {    
    event.preventDefault()
    contextMenu.style.display = 'block';
    contextMenu.style.left = `${event.clientX}px`;
    contextMenu.style.top = `${event.clientY}px`;
    selectedCell.current = event.target
    // document.addEventListener('click', hideTableMenu);
  }

  function hideTableMenu() {
    if(contextMenu)
      contextMenu.style.display = 'none';
    document.removeEventListener('click', hideTableMenu);
  }

  // Add row to the table
  function addRow(position) {
    if (!selectedCell.current) return;
    saveHistory(); 
    let table = selectedCell.current.parentElement.parentElement  
    let numberOfColumns = table.children[0].children.length
    let newRow = table.insertRow(0)    
    for(var i=0;i<numberOfColumns; i++){
      let cell = newRow.insertCell(i)
      cell.style.border = '1px solid #ccc';
      cell.style.padding = '8px';
      cell.style.textAlign = 'center';
      cell.style.height = '40px';
    }

    if(position === 'top')
      table.insertBefore(newRow,selectedCell.current.closest('tr'));    
    else{
      let tr = selectedCell.current.closest('tr').nextSibling
      if(tr)
        table.insertBefore(newRow,tr);
      else
        table.appendChild(newRow)
    }
    hideTableMenu()
  }
function insertColumnRight() {
    if (!selectedCell.current) {
        alert('Please select a cell first!');
        return;
    }
    saveHistory(); // Save the current state to history for undo/redo functionality

    let table = selectedCell.current.parentElement.parentElement;
    const selectedColumnIndex = selectedCell.current.cellIndex;
    console.log('insertColumnRight ' + table + '  ' + selectedColumnIndex);

    for (let row of table.rows) {
        const newCell = row.insertCell(selectedColumnIndex + 1); // Insert a new cell at the next index
        newCell.style.border = '1px solid #ccc';
        newCell.style.padding = '8px';
        newCell.style.textAlign = 'center';
        newCell.style.height = '40px';
    }
    hideTableMenu(); // Hide the table menu after inserting the column
}

  function insertColumnLeft() {
    if (!selectedCell.current) {
        alert('Please select a cell first!');
        return;
    }
    saveHistory(); 

    let table = selectedCell.current.parentElement.parentElement    
    const selectedColumnIndex = selectedCell.current.cellIndex;
    console.log('insertColumnLeft '+table+'  '+selectedColumnIndex)
    for (let row of table.rows) {
        const newCell = row.insertCell(selectedColumnIndex); // Insert a new cell at the column index
        newCell.style.border = '1px solid #ccc';
        newCell.style.padding = '8px';
        newCell.style.textAlign = 'center';
        newCell.style.height = '40px';
    }
    hideTableMenu()
}


  // Delete selected row
  function deleteRow() {
    console.log('deleteRow')
    if (!selectedCell) return;
    saveHistory(); 
    console.log('deleteRow')

    let row = selectedCell.current.closest('tr')
    row.remove()
    hideTableMenu();
  }

  // Delete selected column
  function deleteColumn() {
    if (!selectedCell.current) return;
    saveHistory(); 
    const selectedColumnIndex = selectedCell.current.cellIndex;
    let table = selectedCell.current.parentElement.parentElement              
    for (let row of table.rows) {
      for(let cell of row.cells){
        if(cell.cellIndex === selectedColumnIndex)
          cell.remove()
      }
    }
    hideTableMenu()
  }

function removeResizers() {
    const resizers = document.querySelectorAll('.resizer');
    resizers.forEach(resizer => resizer.remove());
    currentImage = null;
  }

  // Initialize resizing
  function initResize(event) {
    let current;
    if(currentImage)
      current = currentImage;
    else if(currentTable)
      current = currentTable
    currentResizer = event.target;
    startX = event.clientX;
    startY = event.clientY;
    startWidth = parseInt(window.getComputedStyle(current).width, 10);
    startHeight = parseInt(window.getComputedStyle(current).height, 10);

    window.addEventListener('mousemove', resizeImage);
    window.addEventListener('mouseup', stopResize);
  }

  // Resize the image
  function resizeImage(event) {
    let currentElement;
    if(currentImage)
      currentElement = currentImage;
    else if(currentTable)
      currentElement = currentTable
    if (!currentElement) return;

    const dx = event.clientX - startX;
    const dy = event.clientY - startY;

    if (currentResizer.classList.contains('bottom-right')) {
      currentElement.style.width = `${startWidth + dx}px`;
      currentElement.style.height = `${startHeight + dy}px`;
    } else if (currentResizer.classList.contains('bottom-left')) {
      currentElement.style.width = `${startWidth - dx}px`;
      currentElement.style.height = `${startHeight + dy}px`;
    } else if (currentResizer.classList.contains('top-right')) {
      currentElement.style.width = `${startWidth + dx}px`;
      currentElement.style.height = `${startHeight - dy}px`;
    } else if (currentResizer.classList.contains('top-left')) {
      currentElement.style.width = `${startWidth - dx}px`;
      currentElement.style.height = `${startHeight - dy}px`;
    }

    updateResizerPosition();
  }

  // Stop resizing
  function stopResize() {
    window.removeEventListener('mousemove', resizeImage);
    window.removeEventListener('mouseup', stopResize);
  }

  // Update resizer handles position during resizing
  function updateResizerPosition() {
    let currentElement;
    if(currentImage)
      currentElement = currentImage;
    else if(currentTable)
      currentElement = currentTable
    if (!currentElement) return;
    const resizers = currentElement.parentElement.querySelectorAll('.resizer');
    resizers.forEach(resizer => {
      const position = resizer.classList[1];
      if (position === 'top-left') {
        resizer.style.top = `${currentElement.offsetTop - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft - 4}px`;
      } else if (position === 'top-right') {
        resizer.style.top = `${currentElement.offsetTop - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft + currentElement.offsetWidth - 4}px`;
      } else if (position === 'bottom-left') {
        resizer.style.top = `${currentElement.offsetTop + currentElement.offsetHeight - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft - 4}px`;
      } else if (position === 'bottom-right') {
        resizer.style.top = `${currentElement.offsetTop + currentElement.offsetHeight - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft + currentElement.offsetWidth - 4}px`;
      }
    });
  }

  // Get the dimensions of the currently resized image
  function getImageDimensions() {
    if (currentImage) {
      const dimensions = {
        width: currentImage.style.width,
        height: currentImage.style.height
      };
      console.log('Image Dimensions:', dimensions);
      alert(`Width: ${dimensions.width}, Height: ${dimensions.height}`);
    } else {
      alert('No image selected');
    }
  }


function addResizers(image) {    
    const resizers = document.querySelectorAll('.resizer');  
    if(resizers.length > 0){    
      resizers.forEach(resizer => resizer.remove());
      currentImage = null;
      return
    }
    // removeResizers();  // Remove previous resizers
    currentImage = image;

    const resizerPositions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];

    resizerPositions.forEach(position => {
      const resizer = document.createElement('div');
      resizer.classList.add('resizer', position);
      resizer.addEventListener('mousedown', initResize);
      image.parentElement.appendChild(resizer);
    });
  }

function handleImageUpload2(event){
    const file = event.target.files[0];
    console.log('handleImageUpload2 '+file)
    let editor = document.getElementById('editor')  
    // document.body.addEventListener('click', (event)=> removeResizers())  
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = function(e) {
        const div = document.createElement('div')
        const image = document.createElement('img');
        image.addEventListener('click', () => addResizers(image));
        image.src = e.target.result;
        image.style.width = '300px';  // Default image width        
        image.setAttribute('contenteditable', 'false');
        image.ondragstart = function() { return false; };
        div.appendChild(image);
        div.style.position='relative'
        div.style.display='inline-block'
        editor.appendChild(div)
        
      };
      reader.readAsDataURL(file);
    }
}

 function changeFontSize(e){
  console.log('changeFontSize '+e.target.value)
    document.execCommand('fontSize', false, e.target.value+'px');
 }
  function changeFont(e){
  console.log('changeFontSize '+e.target.value)
    document.execCommand('fontName', false, e.target.value);
 }

   // Change text or background color
  function changeColor(command, color) {
    console.log('changeColor '+color+' '+command)
    document.execCommand(command, color);
  }

function insertCodeBlock() {
  console.log('insertCodeBlock')
  // Get the editor element
  const editor = document.querySelector('.editor');

  // Create <pre><code> elements
  const pre = document.createElement('pre');
  const code = document.createElement('code');
  code.textContent = 'Your code here...';  // Placeholder text for the code block
  pre.appendChild(code);

  // Insert the code block at the current cursor position
  // const selection = window.getSelection();
  // if (selection.rangeCount > 0) {
  //   const range = selection.getRangeAt(0);
  //   range.deleteContents();
  //   range.insertNode(pre);
  //   range.setStartAfter(pre);
  //   range.setEndAfter(pre);
  //   selection.removeAllRanges();
  //   selection.addRange(range);
  // } else {
  //   editor.appendChild(pre);
  // }
editor.appendChild(pre);
  // Focus on the new code block and set the caret position
  setCaretToEnd(code);
  code.onClikck=handleCodeBlockEnterKey
  // Add an event listener for 'keydown' to the newly created code block
  // code.addEventListener('keydown', handleCodeBlockEnterKey);
  // pre.addEventListener('keydown', handleCodeBlockEnterKey);
}

function handleCodeBlockEnterKey(event) {
  console.log('handleCodeBlockEnterKey '+event.key)
  if (event.key === 'Enter') {
    event.preventDefault(); // Prevent the default action of creating a new block element

    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      // Insert a newline character at the cursor position
      const newLine = document.createTextNode('\n');
      range.insertNode(newLine);

      // Move the cursor after the newline character
      range.setStartAfter(newLine);
      range.setEndAfter(newLine);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

function setCaretToEnd(element) {
  console.log('setCaretToEnd')
  const range = document.createRange();
  const sel = window.getSelection();
  range.selectNodeContents(element);
  range.collapse(false);
  sel.removeAllRanges();
  sel.addRange(range);
  element.focus();
}


function elementContainsSelection(el) {
    var sel = window.getSelection();
    if (sel.rangeCount > 0) {
        for (var i = 0; i < sel.rangeCount; ++i) {
            if (!el.contains(sel.getRangeAt(i).commonAncestorContainer)) {
                return false;
            }
        }
        return true;
    }
    return false;
}
function addResizersForTable(div) {
    console.log('addResizersForTable '+div.outerHTML)  
    if(!selectedCell.current)  
      return
    const resizers = document.querySelectorAll('.resizer');  
    if(resizers.length > 0){    
      resizers.forEach(resizer => resizer.remove());
      currentTable = null;
      return
    }
    currentTable = selectedCell.current.parentElement.parentElement

    const resizerPositions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];

    resizerPositions.forEach(position => {
      const resizer = document.createElement('div');
      resizer.classList.add('resizer', position);
      resizer.addEventListener('mousedown', initResize);
      currentTable.appendChild(resizer);
    });
}



function changeFontName(e){
  console.log('changeFontSize '+e.target.value)
    props.descriptionRef.current.focus()
    document.execCommand('fontName', true, e.target.value);
 }
  function changeFontSize(e){
  console.log('changeFontSize '+e.target.value)
    props.descriptionRef.current.focus()
    document.execCommand('fontSize', false, e.target.value);
 }
function removeAttachmentFromEdit(id){
    let attachmentFilesListArr = [...attachmentFilesList]
    attachmentFilesListArr.splice(id, 1);
    setAttachmentFilesList(attachmentFilesListArr)
}
function handleAttachment(event){
  console.log('handleAttachment '+attachmentFilesList.length)
    event.preventDefault();
    if(Object.keys(event.target.files).length === 0){
      return
    }
    console.log('size is '+event.target.files[0].size)
    if( event.target.files[0].size > (50 *1000 *1000))
    {
        toast.error('Maximum file size is 50MB');
        return;
    }
    let div = document.createElement('div')
    setAttachmentFilesList([...attachmentFilesList,event.target.files[0]])
}
   // Change text or background color
  function changeForeColor(event, command, color) {
    console.log('changeForeColor '+color+' '+command)
     event.preventDefault()
    props.descriptionRef.current.focus()
    document.execCommand('foreColor', color);
  }
  function changeHiliteColor(event, command, color) {
    console.log('changeHiltoColor '+color+' '+command)
     event.preventDefault()
    props.descriptionRef.current.focus()
    document.execCommand('hiliteColor', color);
  }

  function applyFormat(event,value){
    event.preventDefault()
    props.descriptionRef.current.focus()
    document.execCommand(value);
  }

function handleOrderList(event, type){
    event.preventDefault()
    props.descriptionRef.current.focus()    
    document.execCommand(type, false, null);
}



function insertTable() {

  const editor = props.descriptionRef.current;  
  if (!editor) {
    console.error("Editor not found!");
    return;
  }

  const tableContainer = document.createElement('div');
  tableContainer.style.position = 'relative';
  tableContainer.style.display = 'inline-block';
  tableContainer.style.width = '100%';

  // Create a table element
  const table = document.createElement('table');
  table.style.width = '100%';
  table.style.borderCollapse = 'collapse';

  // Add two rows with five cells each as default
  for (let i = 0; i < 2; i++) {
    const row = table.insertRow();
    for (let j = 0; j < 5; j++) {
      const cell = row.insertCell();
      cell.style.border = '1px solid #ccc';
      cell.style.padding = '8px';
      cell.style.textAlign = 'center';
      cell.style.height = '40px';
    }
  }

  // Add event listeners for table context menu
  table.addEventListener('contextmenu', (event) => {
    event.preventDefault();
    if (event.target.tagName === 'TD') {
      selectedCell.current = event.target;
      showTableMenu(event);
    }
  });
  table.addEventListener('click', (event) => {
    event.preventDefault();
    hideTableMenu()
  });

  tableContainer.appendChild(table);

  props.descriptionRef.current.focus()
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(tableContainer);
  } else {
    editor.appendChild(tableContainer);
  }
}

function deleteTable(event){
  if (!selectedCell.current) return;
  let table = selectedCell.current.parentElement.parentElement  
  table.parentNode.removeChild(table)
  hideTableMenu()
}

function applyCellColor(value){
  if(selectedCell.current)
    selectedCell.current.style.backgroundColor=value
  hideTableMenu()
}

function triggerImageUpload() {
    // Trigger the hidden file input
    document.getElementById("imageUploader").click();
}

function uploadAndInsertImage(event) {
    console.log('uploadAndInsertImage '+event.target.files)
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            console.log('omside onload')
            const imageUrl = e.target.result;
            insertImage(imageUrl);
        };

        reader.readAsDataURL(file); // Read the file as a Data URL
    }
}


function insertImage(imageUrl) {
    const editor = document.getElementById("editor");

    // Create a resizable wrapper
    const wrapper = document.createElement("div");
    wrapper.contentEditable = "false"; // Prevent the wrapper from being edited
    wrapper.style.display = "inline-block";
    wrapper.style.position = "relative";
    wrapper.classList.add("image-wrapper");

    // Create an image element
    const img = document.createElement("img");
    img.src = imageUrl;
    img.style.width = "80%";
    img.style.height = "50%";
    img.alt = "Uploaded Image";

    // Append the image to the wrapper
    wrapper.appendChild(img);

    // Add event listener to toggle resizers
    wrapper.addEventListener("click", (e) => {
        e.stopPropagation();
        toggleResizers(wrapper);
    });

    // Insert the wrapper at the cursor position
    props.descriptionRef.current.focus()
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    range.deleteContents(); // Clear any selected content
    range.insertNode(wrapper); // Insert the wrapper
    range.collapse(false); // Move the cursor after the wrapper
    selection.removeAllRanges();
    selection.addRange(range);

    // Close other active resizers when clicking outside
    document.addEventListener("click", () => removeResizers(wrapper));
}

function toggleResizers(wrapper) {
    if (wrapper.querySelector(".resizer")) {
        removeResizers(wrapper);
    } else {
        addResizers(wrapper);
    }
}

function addResizers(wrapper) {
    const img = wrapper.querySelector("img");
    const directions = ["top-left", "top-right", "bottom-left", "bottom-right"];
    directions.forEach((dir) => {
        const handle = document.createElement("div");
        handle.className = `resizer ${dir}`;
        handle.style.width = "10px";
        handle.style.height = "10px";
        handle.style.backgroundColor = "blue";
        handle.style.position = "absolute";
        handle.style.cursor = `${dir}-resize`;
        switch (dir) {
            case "top-left":
                handle.style.top = "-5px";
                handle.style.left = "-5px";
                break;
            case "top-right":
                handle.style.top = "-5px";
                handle.style.right = "-5px";
                break;
            case "bottom-left":
                handle.style.bottom = "-5px";
                handle.style.left = "-5px";
                break;
            case "bottom-right":
                handle.style.bottom = "-5px";
                handle.style.right = "-5px";
                break;
        }
        wrapper.appendChild(handle);

        // Add resizing functionality
        handle.addEventListener("mousedown", (e) => {
            e.preventDefault();
            startResizing(e, img, handle);
        });
    });
}

function removeResizers(wrapper) {
    const resizers = wrapper.querySelectorAll(".resizer");
    resizers.forEach((resizer) => resizer.remove());
}

function startResizing(e, img, resizer) {
    let startX = e.clientX;
    let startY = e.clientY;
    let startWidth = img.offsetWidth;
    let startHeight = img.offsetHeight;

    function resize(event) {
        const dx = event.clientX - startX;
        const dy = event.clientY - startY;

        if (resizer.classList.contains("bottom-right")) {
            img.style.width = `${startWidth + dx}px`;
            img.style.height = `${startHeight + dy}px`;
        } else if (resizer.classList.contains("bottom-left")) {
            img.style.width = `${startWidth - dx}px`;
            img.style.height = `${startHeight + dy}px`;
        } else if (resizer.classList.contains("top-right")) {
            img.style.width = `${startWidth + dx}px`;
            img.style.height = `${startHeight - dy}px`;
        } else if (resizer.classList.contains("top-left")) {
            img.style.width = `${startWidth - dx}px`;
            img.style.height = `${startHeight - dy}px`;
        }
    }

    function stopResizing() {
        document.removeEventListener("mousemove", resize);
        document.removeEventListener("mouseup", stopResizing);
    }

    document.addEventListener("mousemove", resize);
    document.addEventListener("mouseup", stopResizing);
}


return(
 <>            
      <div style={{position:'absolute', left:'25%',top:'100px', width:'80%', height:'100%'}}> 
          <input type='text' placeholder='title' style={{height:'40px',width:'100%', paddingLeft:'20px'}} required ref={props.titleRef} />
          <div style={{display: 'flex' ,marginTop:'10px', gap:'20px', alignItems:'center'}}>                         
                <select id="header" style={{width:'80px', height:'25px', border: '1px solid #ccc'}} onChange={applyHeaderStyle}>
                    <option value="">Header</option>
                    <option value="H1">H1</option>
                    <option value="H2">H2</option>
                    <option value="H3">H3</option>
                </select>
                  <select id="fontName" onChange={changeFontSize} style={{width:'50px', height:'25px', border: '1px solid #ccc'}}>
                      <option value="2">12px</option>
                      <option value="4">14px</option>
                      <option value="6">16px</option>
                      <option value="7">18px</option>
                      <option value="8">20px</option>
                  </select>
          
                <select id="fontName" onChange={changeFontName} style={{width:'120px', height:'25px', border: '1px solid #ccc'}}>
                    <option value="">Font</option>
                    <option value="Arial">Arial</option>
                    <option value="Courier New">Courier New</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Times New Roman">Times New Roman</option>
                </select>
                <button onClick={(event)=>applyFormat(event, 'bold')} style={{height:'20px', width:'20px', border:'none'}}><img src={Bold} alt='B'/></button>
                <button onClick={(event)=>applyFormat(event, 'italic')} style={{height:'20px', width:'20px', border:'none'}}><img src={Italic} alt='I'/></button>
                <button onClick={(event)=>applyFormat(event, 'underline')} style={{height:'20px', width:'20px', border:'none'}}><img src={Underline} alt='U'/></button>
                <button onClick={(event)=>handleOrderList(event, 'insertOrderedList')} style={{height:'20px', width:'20px', border:'none'}}><img src={OL}/></button>
                <button onClick={(event)=>handleOrderList(event, 'insertUnorderedList')} style={{height:'20px', width:'20px', border:'none'}}><img src={UL}/></button>
                <button style={{height:'20px', width:'20px', border:'none'}}><img src={URL} /></button>

                <button onClick={(event)=>handleOrderList(event, 'justifyLeft')} style={{height:'20px', width:'20px', border:'none'}}><img src={JLeft} /></button>
                <button onClick={(event)=>handleOrderList(event, 'justifyCenter')} style={{height:'20px', width:'20px', border:'none'}}><img src={JCenter} /></button>
                <button onClick={(event)=>handleOrderList(event, 'justifyRight')} style={{height:'20px', width:'20px', border:'none'}}><img src={JRight} /></button>                
                <label for="file-input" style={{marginTop:'10px'}}>           
                  <img class="chatbox-attachment-img" src={require('../../../img/attachment.png')}/>
                </label>
                <input id="file-input" type="file" style = {{display: "none" }} onChange={handleAttachment} />
                <button onClick={triggerImageUpload} style={{height:'20px', width:'20px', border:'none'}}>Image</button>
                <input type="file" id="imageUploader" accept="image/*" style={{display: "none"}} onChange={(event)=>uploadAndInsertImage(event)} />
                <button onClick={insertTable} style={{height:'20px', width:'20px', border:'none'}}>Table</button>
          </div>
                <div style={{border: '2px solid #aaa', width:'90%', borderRadius:'10px'}}>
                    <div contenteditable='true' rows = '8'  placeholder="Type a message" name='description' id='ticket-create-editor' style={{outline:'none', overflowY:'auto',borderRadius:'10px', border:'none', fontWeight:'400',height:'400px',padding:'24px',fontSize:'17px',lineHeight:'1.5' }} ref={props.descriptionRef}></div>
                    <div style={{minHeight:'50px'}}>
                          <div style={{display: 'flex',flexDirection:'row', flexWrap: 'wrap',width:'90%'}}>
                              {attachmentFilesList.map((attachment,index) => 
                                          <Attachment name={attachment.name} 
                                              isEditing={true}
                                              index = {index}
                                              size={attachmentFilesList[index].size}
                                              type={attachmentFilesList[index].type}
                                              removeAttachmentFromEdit={removeAttachmentFromEdit}/>
                              )}            
                          </div>
                    </div>
               </div>
      </div>  
      <div class="context-menu" id="contextMenu">
              <button onClick={deleteTable}>Delete Table</button>
              <button onClick={()=>addRow('top')}>Add Row Above</button>
              <button onClick={()=>addRow('bottom')}>Add Row Below</button>
              <button onClick={()=>insertColumnLeft('left')}>Add Column Left</button>
              <button onClick={()=>insertColumnRight('right')}>Add Column Right</button>
              <button onClick={deleteRow}>Delete Row</button>
              <button onClick={deleteColumn}>Delete Column</button>
              <button onClick={addResizersForTable}>Resize</button>
              <div style={{position:'relative'}}>
                  <button onClick={() => document.getElementById('colorPicker').click()}>
                    Color
                  </button>
                  <input 
                      id='colorPicker'
                      type="color"                      
                      onChange={(e) => applyCellColor(e.target.value)}
                      style={{marginLeft: '10px', verticalAlign: 'middle',visibility:'hidden', position:'absolute'}} 
                    />
              </div>              
        </div> 
    
 </>
)

  
};

export default MyQuill;