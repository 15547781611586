import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import CompanyGroupLine from "./CompanyGroupLine"
import CompanyGroupListDisplayHeader from './CompanyGroupListDisplayHeader'
import CompanyGroupPagination from './CompanyGroupPagination'
import CreateCompanyGroupForm from './CreateCompanyGroupForm'

function CompanyGroupsListDisplay(){
	const [sessionData, setSessionData] = useState(null)
	const [companyGroupLine, setCompanyGroupLine] = useState("")
	const primaryCheckBoxRef = useRef(null)
	const editIdRef = useRef(-1)
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(100)	
	const [checkboxState, setCheckboxState] = useState(false)
	const [showCreateCompanyGroupForm, setShowCreateCompanyGroupForm] = useState(false)
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)

	useEffect(() => {
		groupButtonsRef.current.style.visibility = 'hidden'
		loadCompanyGroups();
		setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
	},[])//use effect
function loadCompanyGroups(){
		axiosClient.getRequest(constants.COMPANY_GROUP_URL+'?pageNo='+pageNo+'&pageSize='+pageSize)
		.then(function (response) {
		let companyList = null;
		companyList = response.data.map((element, index) => (									
						<CompanyGroupLine company_group= {element} checkBoxClicked={checkBoxClicked} editCompanyGroup={editCompanyGroup}/>
		));
		setCompanyGroupLine((Object.keys(companyList).length === 0  ? 'No Records Found...' : companyList))
		}).catch(function (response) {
		    console.log(response);
		});	
}
function createCompanyGroup(){
	let companiesChecked = []
	let elements = document.getElementsByClassName('company-checkbox')
	for (var i = 0; i < elements.length; i++) {
		if(elements[i].checked)
			companiesChecked.push(elements[i].id)
	
	}
	axiosClient.getRequest(constants.COMPANY_GROUP_URL)
	.then(function (response) {
	let companyList = null;
	companyList = response.data.map((element, index) => (
					<CompanyGroupLine company_group= {element} checkBoxClicked={checkBoxClicked} editCompanyGroup={editCompanyGroup}/>
	));
	setCompanyGroupLine((Object.keys(companyList).length === 0  ? 'No Records Found...' : companyList))
	console.log("CompanyGroup line set")
	}).catch(function (response) {
	    console.log(response);
	});		
}

function checkBoxClicked(id, value){
	let idsTemp = ids;
	if(value){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'	
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}
}

function showCreateCompanyGroupFormFun(){
	setShowCreateCompanyGroupForm(true)
}

function performAction(type){
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.COMPANY_GROUP_URL+'delete';
	else if(type === 'block')
		url = constants.COMPANY_GROUP_URL+'block';
	else if(type === 'spam')
		url = constants.COMPANY_GROUP_URL+'spam';

//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadCompanyGroups();
		let elements = document.getElementsByClassName('company-group-line-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		}           
       }).catch((error) => {
           console.log(error)
       });      
       groupButtonsRef.current.style.visibility = 'hidden'
       primaryCheckBoxRef.current.checked=false
}

function searchCompanyGroups(event){
	let pageNo=0;
	let pageSize=10;
	axiosClient.getRequest(constants.COMPANY_GROUP_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pageNo+'&pageSize='+pageSize)
	.then(function (response) {
	let companyList = null;
	companyList = response.data.map((element, index) => (					
					<CompanyGroupLine company_group= {element} checkBoxClicked={checkBoxClicked} editCompanyGroup={editCompanyGroup}/>
	));
	setCompanyGroupLine((Object.keys(companyList).length === 0  ? 'No Records Found...' : companyList))
	}).catch(function (response) {
	    console.log(response);
	});	
}

function showCreateCompanyGroupFormFun(){
	setShowCreateCompanyGroupForm(true)
}
function editCompanyGroup(id){
	editIdRef.current = id
	setShowCreateCompanyGroupForm(true)
}

function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('company-group-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}

function closeCreateWindow(){
	setShowCreateCompanyGroupForm(false)
	editIdRef.current = -1
}
return (
	<>
		   <CompanyGroupListDisplayHeader searchCompanyGroups={searchCompanyGroups} 
	   								  showCreateCompanyGroupFormFun={showCreateCompanyGroupFormFun}
	   								  groupButtonsRef={groupButtonsRef}
	   								  performAction={performAction}/>

			<div class="main-body">
			   <div class="container" style={{padding:'20px',paddingTop:'10px', minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
         <div class="row">
            <div class="col-lg-12">
               <div class>
                  <div class="table-responsive">
                     <table class="table table-nowrap align-middle table-borderless">
                        <thead>
                           <tr>
                              <th scope="col" class="ps-4" style={{width: "50px",color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>
                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" style={{border: '1px solid #A9A9A9'}} ref={primaryCheckBoxRef} onClick={onPrimaryCheckboxClicked}/></div>
                              </th>
                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Name</th>
                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Number Of Companies</th>                                                            
                           </tr>
                        </thead>
                        <tbody>
                        	{companyGroupLine}                    
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>         
      </div>

					{ showCreateCompanyGroupForm ? <CreateCompanyGroupForm closeCreateWindow={closeCreateWindow} editIdRef={editIdRef}/> : '' }
				</div>
	</>
 );

}
export default CompanyGroupsListDisplay;
