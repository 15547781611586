import React from 'react';
import {useNavigate} from "react-router-dom"
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
import * as constants from '../../../js/constants'

function TicketLine(props){

const navigate = useNavigate(); 

function checkBoxClicked(event){
	props.checkBoxClicked(props.contact.id, event.target.checked)
} 

function displayReporterImage(){	
   if(props.ticket['reporter_contact'] === null)
   		return '-'
   if(props.ticket['reporter_contact'].deleted === true ||
   	  props.ticket['reporter_contact'].image === 'null'){
   	return (<ProfilePicture name={props.ticket['reporter_contact'].name} />)
   }
   return <img src = 'require({props.ticket["reporter_contact"].image})' class='list-image'/>
}
function displayReporter(){
   if(props.ticket['reporter_contact'] === null)
   		return <span style={{color:'rgb(107, 114, 128)'}}>--</span>
   if(props.ticket['reporter_contact'].deleted === 'true'){
   	return props.ticket['reporter_contact'].name

   }
  return <div onClick={openReporter} >
  			{
  				props.ticket['reporter_contact'].image === 'null' ? 
		            <ProfilePicture name={props.ticket['reporter_contact'].name} />
		            		: <img class="list-image"  src={ props.ticket['reporter_contact'].image} /> 
		    }
	            <a href="" style = {{textDecoration: "none", marginLeft: '10px',fontWeight:'800',fonFamily:'Helvetica',fontSize:'12px'}}>{props.ticket['reporter_contact'] === null ?'--' : props.ticket['reporter_contact'].name}</a>
	     </div>
  	
}
function openTicket(id){
	let path = constants.TICKET_URL_ABS +props.ticket.id ;
    navigate(path);
}
function openReporter(){
	if((props.ticket['reporter_contact'] === null))
		return	
	let path = constants.CONTACT_URL_ABS +props.ticket['reporter_contact'].id ;
    navigate(path);	
}
function openAssignee(){
	if((props.ticket['assignee'] === null))
		return
	let path = constants.AGENT_URL_ABS +props.ticket['assignee'].id ;
    navigate(path);	
}

function displayTicketQuickView(){
	props.displayTicketQuickView(props.ticket.id)
}

return (
	        <tr onMouseOver={displayTicketQuickView} style={{fontFamily: 'font-family: BogleWeb, MyriadPro, Helvetica Neue, Helvetica, Arial, sans-serif',color: '#2e2f32'}}>
	        	<td class='add-ellipsis' style={{maxWidth:'50px'}}>
					{displayReporter()}					
	            </td>
	            <td onClick={openTicket} style={{fontWeight:'500'}}>				
					<div class='ticket-summary-ellipsis' style={{fontWeight:'500',fontSize:'16px',color:'rgb(44, 92, 197)'}}>#{props.ticket.ticket_number}&nbsp;&nbsp; {props.ticket.summary}</div>
	            </td>	            
	            <td style={{textAlign: 'center'}}>
					< a href='#' onClick={openAssignee} style={{textDecoration:'none' }}> {props.ticket.assignee === null ? <span style={{color:'rgb(107, 114, 128)'}}>--</span> : props.ticket.assignee.name}</a>
	            </td>
	            <td><span>{props.ticket.status} </span></td>
	            <td>
	            	<span className={'priority-badge priority-' + props.ticket.priority.toUpperCase()} style={{width:'100px'}}>{props.ticket.priority.toUpperCase()}</span>	            	
	            </td>
	            <td>
	            	{props.ticket.first_response === 'true' ? 'Yes' : 'No'}	            	
	            </td>	            
	         </tr>
            );

}

export default TicketLine;