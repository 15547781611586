import React, { useEffect, useRef, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'
import {useNavigate, useParams} from "react-router-dom"
import AWS from 'aws-sdk';
import { ToastContainer, toast } from 'react-toastify';
import DynamicForm from '../../CustomFields/js/DynamicForm'

function CreateContactForm(props){
  const [formData, setFormData] = useState([]);
  const [image, setImage] = useState(null)
  const spinnerRef = useRef(null)
  const [imageModified, setImageModified] = useState(false)
  const uploadImageRef = useRef(null)
  const imageRemoveRef = useRef(null)
  const workPhoneRef = useRef(null)
  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const numberRef = useRef(null)
  const uniqueIdentifierRef = useRef(null)
  const addressRef = useRef(null)
  const notesRef = useRef(null)
  const companyRef = useRef(null)
  const addWorkPhoneLink = useRef(null)
  const removeWorkPhoneLink = useRef(null)
  const addAddressLink = useRef(null)
  const errorRef = useRef(null)
  const navigate = useNavigate()
  const params = useParams();
  let selectedCompanyId = -1;
  const COMPANY_DROP_DOWN_INPUT_POS = 0;
  const COMPANY_DROP_DOWN_MENU_POS = 1;
  const COMPANY_DROP_DOWN_HIDDEN_INPUT_POS = 2;
  let error = '';
  let editFilled = false;
  const [customFields, setCustomFields] = useState([])
  
  useEffect(() => {   
    errorRef.current.style.display = 'none'
    spinnerRef.current.style.display = 'none'
    imageRemoveRef.current.style.display='none'
    removeWorkPhoneLink.current.style.visibility = 'hidden'
    if(isEdit() && !editFilled){
      editFilled = true;
      fillForm(); 
    }  
  },[])


  useEffect(() => { 
    if(!isEdit())  {
        axiosClient.getRequest(constants.CUSTOM_FIELDS+"display?name=Contacts")
        .then(function (response) {
            setCustomFields(response.data)
              let formDataTemp =   response.data.map((field) => ({
                    id: field.id,
                    name: field.name,
                    formValues: field.type === 'checkbox-group' ? '' : field.type === 'checkbox' ? false : '',
                    type: field.type,
                    apLabel: field.apLabel,
                    apMandatorySubmission: field.apMandatorySubmission,
                    apEditable:field.apEditable,
                    values:field.values
                  }))
               setFormData(formDataTemp) 
        }).catch(function (response) {
            console.log(response);
        });
    }
  },[])

function isEdit(){
  return props.editIdRef && (props.editIdRef.current !== undefined) && (props.editIdRef.current !== -1)
}
function fillForm(){
  console.log('fillForm')
  let id = props.editIdRef.current
  axiosClient.getRequest(constants.CONTACT_URL+id)
    .then(function (response) {
        if(response.data.image !== 'null'){
          document.getElementById("drop-area").style.backgroundImage=`url(${response.data.image})`;
          uploadImageRef.current.style.display='none'
          imageRemoveRef.current.style.display='inline-block'
        }
        if(response.data.customFields !== 'null'){
          let jsonCustomFields = JSON.parse(response.data.customFields.replace(/'/g, '"'))
          setCustomFields(jsonCustomFields)
          let formDataTemp =   jsonCustomFields.map((field) => ({
                    id: field.id,
                    name: field.name,
                    formValues: field.formValues,
                    type: field.type,
                    apLabel: field.apLabel,
                    values: field.values
                  }))
          console.log('customFields '+ JSON.stringify(response.data))
          setFormData(formDataTemp) 
        }
  
        nameRef.current.value = response.data.name
        emailRef.current.value = (response.data.email === 'null' ? '' : response.data.email)
        numberRef.current.value = (response.data.phoneNumber === 'null' ? '' : response.data.phoneNumber)
        if(response.data.company !== null){        
          companyRef.current.value =response.data.company.name
          let ele = document.getElementsByName('company-hidden-input')[0]
          ele.value = response.data.company.id
        }
        addressRef.current.value = (response.data.address === 'null' ? '' : response.data.address.replaceAll("<br/>","\n"))
        notesRef.current.value = (response.data.notes === 'null' ? '' : response.data.notes.replaceAll("<br/>","\n"))
        uniqueIdentifierRef.current.value = (response.data.uniqueIdentifier === 'null' ? '' : response.data.uniqueIdentifier)
        // if(response.data.image !== 'null'){
        //   document.getElementById("drop-area").style.backgroundImage=`url(${response.data.image})`;
        // }
        if(response.data.workPhone !== 'null' && response.data.workPhone !== '')
          workPhoneRef.current.lastChild.value = response.data.workPhone
        if(response.data.workPhone1 !== 'null' && response.data.workPhone1 !== ''){          
          if(workPhoneRef.current.lastChild.value !== '')
            addWorkPhoneField()
          workPhoneRef.current.lastChild.value = response.data.workPhone1
        }
        if(response.data.workPhone2 !== 'null' && response.data.workPhone2 !== ''){
          if(workPhoneRef.current.lastChild.value !== '')
            addWorkPhoneField()
          workPhoneRef.current.lastChild.value = response.data.workPhone2
        }
    }).catch(function (error) {
        console.log(error)
        if(error.response.data.error === "CONTACT_DOESNOT_EXIST"){
          toast.error('Contact does not exist')
          props.closeCreateWindow()
          return
        }  
        
      
    });
}
function validateEmail(email){
  var emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;  
  if(email.length > 330)
    return false;
  return (email.match(emailReg) !== null)
}

function validatePhoneNumber(number){
  return true
  return String(number)
    .toLowerCase()
    .match(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    );
}

function workPhonesHaveSameValue(event){
    let workPhones = [];
    if(event.target.workPhone.value !== '')workPhones.push(event.target.workPhone.value)
    if(event.target.workPhone1 !== undefined && event.target.workPhone1.value !== '')workPhones.push(event.target.workPhone1.value)
    if(event.target.workPhone2 !== undefined && event.target.workPhone2.value !== '')workPhones.push(event.target.workPhone2.value)
    if(workPhones.length < 2)  
      return false;
    if(workPhones.length == 2){
          return workPhones[0] === workPhones[1]
    }
    if(workPhones.length == 3){
          return (workPhones[0] === workPhones[1] ||
   workPhones[2] === workPhones[1] ||
   workPhones[0] === workPhones[2])
    }
}
function capitalizeWords(str){
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}

function formDataToJson(formData) {
    const obj=[]
    console.log('formDataToJson '+JSON.stringify(formData))
    formData.forEach((value, key) => {
        obj.push(value)      
    });
    return JSON.stringify(obj);
}
  function handleSubmit(event){
    event.preventDefault()
    console.log('handleSubmit '+JSON.stringify(formData))    
    errorRef.current.innerHTML = ''
    errorRef.current.style.display = 'none'
    
    let address = [{},{},{}]
    if(error !== ''){
      displayError(error)
      return
    }
    //validations
    if(event.target.email.value !== '' && !validateEmail(event.target.email.value)){
        displayError('Invalid Email Pattern')
        return
    }
    if(event.target.mobileNumber.value !== '' && !validatePhoneNumber(event.target.mobileNumber.value)){
        displayError('Invalid Phone number')
        return
    }
    if(workPhonesHaveSameValue(event)){
        displayError('Repeated Work Phone numbers present')        
        return        
    }  
    //create payload
    spinnerRef.current.style.display = 'inline-block'
    let payload = {'fullName': capitalizeWords(event.target.fullName.value),
                    'email': event.target.email.value,
                    "imageModified":imageModified,
                    'workPhone': event.target.workPhone.value,
                    'mobileNumber': event.target.mobileNumber.value,
                    'notes': (event.target.notes.value !== '' ? event.target.notes.value : event.target.notes.value),
                    'uniqueIdentifier': event.target.uniqueIdentifier.value,
                    'address': (event.target.address.value !== '' ? event.target.address.value : event.target.address.value),
                    'workPhone1': (event.target.workPhone1 !== undefined) ? event.target.workPhone1.value : null,
                    'workPhone2': event.target.workPhone2 !== undefined ? event.target.workPhone2.value : null,
                    customPayload: formDataToJson(formData)
                  }

    if(image !== null)
        payload['image'] = image.name
    let company = {}
   if(event.target['company-hidden-input'].value !== '-1')
      company['id']=  event.target['company-hidden-input'].value
    else
      company['name'] = event.target.company.value
    payload['company'] = company
    if(!isEdit()){
        console.log('sending post request')
          axiosClient.postRequest(constants.CONTACT_URL, payload,{headers: {
            'Content-Type': 'multipart/form-data'
          }})
          .then(function (response) {      
            if(image !== null){
                axiosClient.putExternalRequest(response.data.image, image,{headers: {
                  'Content-Type': 'multipart/form-data',
                  'Content-Length': image.size
                }})
                .then(function (response2) { 
                  console.log('contact creted '+JSON.stringify(response.data))
                    spinnerRef.current.style.display = 'none'
                    toast.success('Contact created ...')
                    if(props.chatIdRef)
                      props.contactRef.current = response.data
                    props.closeCreateWindow(true)
                }).catch((error) => {
                  spinnerRef.current.style.display = 'none'
                })  
            } else{
                    spinnerRef.current.style.display = 'none'
                    toast.success('Contact created ...')
                    if(props.chatIdRef)
                      props.contactRef.current = response.data
                    props.closeCreateWindow(true)              
            }
          }).catch((error) => {
            console.log(error)
              if(error.response.status === 400){
                if(error.response.data.error === '')
                  displayError('Error in processing the request')
                else
                  displayError(error.response.data.error)
              }else{
                displayError('Error in processing the request')
              }
              spinnerRef.current.style.display = 'none'
          });
    }else{
      console.log('sending patch request')
      axiosClient.patchRequest(constants.CONTACT_URL+props.editIdRef.current, payload,{headers: {
            'Content-Type': 'multipart/form-data'
          }})
          .then(function (response) {      
            if(image !== null){
                axiosClient.putExternalRequest(response.data.image, image,{headers: {
                  'Content-Type': 'multipart/form-data',
                  'Content-Length': image.size
                }})
                .then(function (response2) {  
                    toast.success('Contact Updated')          
                    spinnerRef.current.style.display = 'none'
                    props.closeCreateWindow(true)                
                  }).catch((error) => {
                    spinnerRef.current.style.display = 'none'
                })  
            } else{
                  toast.success('Contact Updated')          
                  props.closeCreateWindow(true)
                  spinnerRef.current.style.display = 'none'
            }
          }).catch((error) => {
            console.log(error)
              if(error.response.status === 400){
                if(error.response.data.error === '')
                  displayError('Error in processing the request')
                else
                  displayError(error.response.data.error)
              }else{
                displayError('Error in processing the request')
              }
              spinnerRef.current.style.display = 'none'
          });
    }
  }

function onSelectDropdown(event){
      event.target.parentElement.parentElement.children[COMPANY_DROP_DOWN_INPUT_POS].value = event.target.innerHTML.split(";")[0]      
      event.target.parentElement.parentElement.children[COMPANY_DROP_DOWN_MENU_POS].style.display = 'none'
      event.target.parentElement.parentElement.children[COMPANY_DROP_DOWN_HIDDEN_INPUT_POS].value=event.target.name
  }

  function addChildToCompanyDropdown(element, event){
      let aTag = document.createElement('a')
      aTag.name=element.id
      aTag.innerHTML = element.name
      aTag.onclick = onSelectDropdown
      event.target.parentElement.children[COMPANY_DROP_DOWN_MENU_POS].appendChild(aTag)
      event.target.parentElement.children[COMPANY_DROP_DOWN_MENU_POS].style.display = 'block'
  }

  function handleCompanyName(event){      
      event.target.parentElement.children[COMPANY_DROP_DOWN_MENU_POS].innerHTML = ''
      event.target.parentElement.children[COMPANY_DROP_DOWN_MENU_POS].style.display = 'none'
      event.target.parentElement.children[COMPANY_DROP_DOWN_HIDDEN_INPUT_POS].value = -1;
      if(event.target.value.length < 3)
        return
      axiosClient.getRequest(constants.COMPANY_URL+"search_by_name?keyword="+event.target.value)
      .then(function (response) {
          response.data.forEach((element, index)=>{             
              addChildToCompanyDropdown(element,event)
          })
      }).catch(function (response) {
          console.log(response);
      });
  }

  function checkIfEmailExist(event){
    return
    event.target.setCustomValidity('')
    event.target.style.borderColor = 'black'
    error = false
    errorRef.current.innerHTML = ''
    errorRef.current.style.display='none';
    axiosClient.getRequest(constants.CONTACT_URL+'email_check?email='+event.target.value)
    .then(function (response) {
      console.log(response.data)
      if(response.data === true){
        displayError('Contact with email already exist...')
        error = 'Contact with email already exist...'
      }else{
        error = ''
      }
    }).catch(function (response) {
        console.log(response);
        error = ''
    });
  }


  function addWorkPhoneField(){
    console.log("addNewAddressField "+workPhoneRef.current.children.length)
      if(workPhoneRef.current.children.length >= 3)
        return
      // let div = document.createElement('div')
      let input = document.createElement('input')
      input.style.width = '90%'
      input.style.marginTop = '20px'
      input.name = "workPhone"+workPhoneRef.current.children.length
      input.placeholder = 'Work Phone'
      // div.innerHTML = '<input type="text" name="workPhone'+workPhoneRef.current.children.length+'"  placeholder = "Work Phone" style={{width: "35%",  borderRadius: "5px"}} />'
      workPhoneRef.current.appendChild(input)
      removeWorkPhoneLink.current.style.visibility = 'visible'
      if(workPhoneRef.current.children.length  >= 3)
        addWorkPhoneLink.current.style.visibility='hidden'
  }

  function removeWorkPhoneField(){
         if(workPhoneRef.current.children.length <= 1){
          removeWorkPhoneLink.current.style.visibility = 'hidden'
          return
         }
         workPhoneRef.current.removeChild(workPhoneRef.current.lastChild)
         addWorkPhoneLink.current.style.visibility='visible'
         if(workPhoneRef.current.children.length <= 1)
            removeWorkPhoneLink.current.style.visibility = 'hidden'
  }

function removeImage(event){
  event.preventDefault()
  document.getElementById("drop-area").style.backgroundImage = null;
  setImage(null)
  uploadImageRef.current.style.display='inline'
  imageRemoveRef.current.style.display='none'
  setImageModified(true)
}

function dropAreaClicked(){
  console.log('dropAreaClicked')
}
function uploadContactImage(event){
  event.target.setCustomValidity('')
    if(Object.keys(event.target.files).length === 0){
      return
    }
    uploadImageRef.current.style.display='none'
    if(event.target.files[0].size > 2000000){
      event.target.setCustomValidity('Maximum image size is 2MB')
      return
    }
    setImage(event.target.files[0])
    var imgLink = URL.createObjectURL(event.target.files[0])
    document.getElementById("drop-area").style.backgroundImage=`url(${imgLink})`;
    setImageModified(true)
}   

  return (
    <div class="create-wrapper">      
      <form onSubmit={handleSubmit}>          
          <div style={{ padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
              <a href="javascript:void(0)" class="create-closebtn" onClick={()=>props.closeCreateWindow()}>&times;</a>
              <div style={{color:'#044caf',fontWeight: '800', fontSize:'20px'}}>+ Create Contact</div>
              <div ref={errorRef}  class='create-error'/>              
              <div class='create-line'></div> 
              <div class = 'create-display-image-wrapper'>
                  <div class='drop-area-wrapper'>                          
                    <label for="input-file" id="drop-area" onChange={uploadContactImage}>
                        <div>
                            <img src={require('../../../img/placeholder.png')} style={{height:'100%',width:'100%'}} ref={uploadImageRef}/>
                        </div>
                        <input type="file" accept=".png, .jpg, .jpeg" id="input-file" hidden name='image'/>
                    </label>              
                  </div>  
                  <div class='create-form-label' style={{marginLeft: '2%',color: '#2e2f32',fontSize:'18px' }}>
                    Upload Image of the Contact
                  </div>  
              </div> 
              <div ref={imageRemoveRef}><a href='' style={{fontSize:'14px',textDecoration:'none',marginLeft:'50px' }} onClick={removeImage}>Remove</a></div>         
              
              <div class='create-form-label' style={{ marginTop: "3%"}}>Full Name:<p class='red-color'>*</p></div>
              <div><input type="text" name="fullName" style={{width: "90%"}} placeholder={'Full Name'} required ref={nameRef} maxlength='500'/></div>
              <div style={{ marginTop: "3%"}} class='create-form-label'>Email:<p class='red-color'>*</p></div>
              <div><input  type="email" name="email"  style={{width: "90%", outline: 'none' }}   onBlur={checkIfEmailExist}  placeholder={'Email'} required  ref={emailRef} maxlength='320'/> </div>
              <div style={{marginTop: "3%"}} class='create-form-label'>Contact Number:</div>
              <div><input  type="text" name="mobileNumber"  style={{width: "90%"}} placeholder={'Contact Number'} ref={numberRef} maxlength='16'/></div>
              <div style={{marginTop: "3%"}} class='create-form-label'> WorkPhone:
              <div ref={workPhoneRef}> 
              <input  type="text" name="workPhone"  style={{width: "90%"}} placeholder={'Work Phone'} maxlength='12'/>
              </div>
              <div>
                 <a style={{fontSize: '15px', fontWeight: 'bolder', display: 'inline'}} href="#" onClick={addWorkPhoneField} ref={addWorkPhoneLink} >Add Another</a>
                 <a style={{fontSize: '15px', fontWeight: 'bolder', marginRight: '10%', float:'right'}}href="#" onClick={removeWorkPhoneField} ref={removeWorkPhoneLink}>Remove </a>
              </div>
              <div style={{marginTop: "3%"}} class='create-form-label'>Unique Id:</div>
                <div><input  type="text" name="uniqueIdentifier" maxlength='50' style={{width: "90%"}} placeholder={'Unique Identifer'} ref={uniqueIdentifierRef} maxlength='50'/>  
              </div>
              <div class='create-form-label' style={{ marginTop: "3%"}}>Company:</div>
              <div style={{position:'relative' }}>
                <input  type="text"  style={{width: "90%"}} onChange={handleCompanyName} name="company" ref={companyRef} maxlength='50'/>
                <div style={{display: 'none', position: 'absolute'}} class='create-dropdown-content'/>
                <input  style= {{display: 'none'}} name = 'company-hidden-input' value = '-1'/>
              </div>  
              <div style={{ marginTop: "3%"}} class='create-form-label'>
                Address:
              </div>
               <div>
               <div style={{width: "90%"}}>
                    <textarea  name="address"  style={{width: "100%", height: "100px" }} placeholder={'Complete Address'} ref={addressRef}/>
               </div>
               <div class='create-form-label' style={{ marginTop: "3%"}}>Notes:</div>              
               <div style={{width: "90%"}}>
                 <textarea  name="notes" style={{width: "100%", height: "100px",color:'black'}} placeholder={'Some notes'} ref={notesRef}/>
               </div>

                  <DynamicForm customFields={customFields} setFormData={setFormData} formData={formData} edit={isEdit()}/>
               </div>                
              </div>
            </div>  
            <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
              <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}}>{isEdit() ? 'Update Contact' : 'Create Contact'}</button>
              <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
            </div>
            
          </form>
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>                      
          </div>

    );
}

export default CreateContactForm;