import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../../js/Axios';
import * as constants from '../../../../../js/constants'
import TicketFirstResponseTimeDashboard from './TicketFirstResponseTimeDashboard'
import '../../css/dashboard.css'
import "react-datepicker/dist/react-datepicker.css";
import TicketCountDashboard from './TicketCountDashboard'
import DatePicker from "react-datepicker";
import TicketAverageFRTDashboard from './TicketAverageFRTDashboard'
import TicketAverageResolutionTimeDashboard from './TicketAverageResolutionTimeDashboard'
import TicketsPriorityChart from './TicketsPriorityChart'
import TicketsStatusChart from './TicketsStatusChart'
import CallCountDashboard from '../CallDashboard/CallCountDashboard'
import TicketsByStatusChart from './TicketsByStatusChart'
function TicketDashboard(){
	const [dropdownValue, setDropDownValue] = useState('Today')
	const dropdownRef = useRef(null)
	const [value, onChange] = useState(new Date());
	const [data, setData] = useState({})
	const [callData, setCallData] = useState({})
	const DAY = 'day'
let loadComponent = true

useEffect(() => {
	if(loadComponent){
		loadComponent = false;
		loadDashboard(DAY)	
	}
	
},[])//use effect

function loadDashboard(durationParam){
	console.log('loadDashboard')
	axiosClient.getRequest(constants.TICKET_URL+'dashboard?duration='+durationParam)
	.then(function (response) {
		console.log('dashboard '+JSON.stringify(response.data))
		setData(response.data)
	}).catch(function (response) {
	    console.log(response);
	});	
	axiosClient.getRequest(constants.CALL_URL+'dashboard?duration='+durationParam)
	.then(function (response) {
		console.log('call dashboard '+JSON.stringify(response.data))
		setCallData(response.data)
	}).catch(function (response) {
	    console.log(response);
	});	
}
function displayDropdown(event){
	if(dropdownRef.current.style.display === 'none')
		dropdownRef.current.style.display = 'block'
	else
		dropdownRef.current.style.display = 'none'
}

function hideDropdown(event, type,duration){
event.preventDefault()
	if(type === undefined){
		dropdownRef.current.style.display = 'none'
		return
	}
	setDropDownValue(type)
	loadDashboard(duration)	
}

// function onChange(event){}
return(<div style={{height:'100%', overflowY: 'auto',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;', margin: '0 30px'}}>	
		<div style={{position:'relative', display:'block',textDecoration:'underline',cursor:'pointer',fontSize:'14px',fontWeight:'600'}} onClick={displayDropdown} >{dropdownValue}
		  <div ref={dropdownRef} class='create-dropdown-content' style={{width:'300px',top:'30px', display:'none'}}>
			<div onClick={(event)=>hideDropdown(event, 'Today', 'day')}><a href=''>Today</a></div>
			<div onClick={(event)=>hideDropdown(event, 'Last 24 Hours', '24hours')}><a href=''>Last 24 Hours</a></div>
			<a onClick={(event)=>hideDropdown(event, 'This Week', 'week')} href=''>This Week</a>
			<div onClick={(event)=>hideDropdown(event, 'Last Seven Days', 'lastSevenDays')}><a href='' >Last Seven Days</a></div>
			<div onClick={(event)=>hideDropdown(event, 'This Month', 'month')}><a href=''>This Month</a></div>
			<div onClick={(event)=>hideDropdown(event, 'Last Thirty Days','lastThirtyDays')}><a href='' >Last Thirty Days</a></div>
			<div onClick={(event)=>hideDropdown(event, 'Last Three Months', 'threemonths')}><a href='' >Last Three Month</a></div>			
			<div onClick={(event)=>hideDropdown(event, 'This Year', 'year')}><a href='' >This Year</a></div>
			<div onClick={(event)=>hideDropdown(event, 'Date')}><a href='' >Custom Date</a></div>
		  </div>			
		</div>
			<div style={{width: '100%' }}>
				<div >
						<TicketCountDashboard data={data}/>
				</div>
				<div class='row'>
						<CallCountDashboard data={callData}/>
				</div>
			</div>
		<div style={{display:'flex',marginTop:'2%',justifyContent:'space-around'}}>			
			{
				
			  /*<TicketsByStatusChart data={data}/>
			  <TicketsByPriorityChart data={data}/> 
		  */}
			<div class='container'>
				<div class='row'>
					<div class='col-md-6'>
						<TicketsStatusChart data={data} />
					</div>
					<div class='col-md-6'>
						<TicketsPriorityChart data={data} />
					</div>
				</div>
			</div>			
			
			
		</div>
		<div style={{display:'flex',marginTop:'2%', justifyContent:'space-around'}}>
		{/*	<TicketAverageFRTDashboard data={data}/>
			<TicketAverageResolutionTimeDashboard data={data}/>		
		*/}
		</div>
	</div>)
}


export default TicketDashboard;