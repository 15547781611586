import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { usePermissions } from '../../PermissionsProvider';

function SubCategoryLine(props){
	const { isActionAllowed, isAgent } = usePermissions();
	const menuRef = useRef(null)
	function displayMenu(){
		if(menuRef.current)
			menuRef.current.style.display = 'inline-block'
	}	

	function hideMenu(){
		if(menuRef.current)
			menuRef.current.style.display = 'none'
	}

	function openSubCategory(){
		props.openSubCategory(props.element.name, props.element.description, props.element.id)
	}

	return (
	<>
		<div style={{borderRadius:'5px', padingLeft:'15px', padding:'5px', cursor: 'pointer'}} onMouseEnter={displayMenu} onMouseLeave={hideMenu} onClick={()=>props.displayArticles(props.element.articles, props.element.id)}>
			<div style={{display:'inline-block',width:'70%',fontSize:'16px',fontWeight:'500',color:'black',letterSpacing:'1px'}} class='add-ellipsis'>{props.name}</div>
			{ isAgent() || isActionAllowed('EDIT_CATEGORY') || isActionAllowed('CREATE_ATICLE') ?
				<div style={{position:'absolute', right:'0px', display:'none'}} ref={menuRef}>
					 <Dropdown style={{verticalAlign:'center'}}>
					      <Dropdown.Toggle style={{backgroundColor:'white', border:'white',paddingTop:'0px'}}>
					       	<img src={require('../../../img/dots.png')} alt='' style={{ height: "20px"}}/>
					      </Dropdown.Toggle>

					      <Dropdown.Menu>					        
					   {(isAgent() || isActionAllowed('EDIT_CATEGORY')) &&    ( <Dropdown.Item onClick={openSubCategory}>Edit</Dropdown.Item> )}
					   {(isAgent() || isActionAllowed('CREATE_ARTICLE')) &&     (<Dropdown.Item onClick={(event)=>props.editArticle(null)}>Article</Dropdown.Item> )}
					      </Dropdown.Menu>
					    </Dropdown>		    								
				</div>				
			 :''
			}
		</div>								
    </>
	)
}

export default SubCategoryLine;