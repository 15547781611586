import React, { useState, useCallback , useEffect} from 'react';
import NodePanel from './NodePanel'
import ReactFlow, {applyNodeChanges, applyEdgeChanges, addEdge, 
  useNodesState,useEdgesState,MarkerType,Background,Controls }from 'react-flow-renderer';
import ChatMenuBox from './ChatMenuBox'
import * as constants from '../../../js/constants';
import * as axiosClient from '../../../js/Axios';
import CreateChatBotWindow from './CreateChatBotWindow'
import { ToastContainer, toast } from 'react-toastify';
import { useParams} from "react-router-dom"



const initialNodes = [
  { id: '1', type: 'chatMenuBox', position: { x: 0, y: 0 },  data: {name: 'Hello',message: 'Welcome to {{company.name}}. How can I help you?', type:'message'} }
];


const nodeTypes = {
  chatMenuBox: ChatMenuBox, // Register your custom node
};
const CreateChatBotForm = () => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [edge, setEdge] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [displayNodePanel, setDisplayNodePanel] = useState(false)
  const [create, setCreate] = useState(false)
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const params = useParams();

  useEffect(()=>{
      // initialNodes[0]['data']['onClick']= ()=>handleNodeClick('1')
      setNodes(initialNodes)
      if(params.id){
            axiosClient.getRequest(constants.CHAT_BOT_URL+'/'+params.id)
             .then(function (response) {
                 console.log('borRquest '+JSON.stringify(response.data))
                 setNodes(response.data.nodes)
                 setEdges(response.data.edges)
                 setName(response.data.name)
             }).catch((error) => {

             });
      }

  },[])

  // const onConnect = useCallback(
  //   (params) => setEdges((eds) => addEdge(params, eds)),
  //   [],
  // );
const onConnect = (connection) => {
  setEdges((prevEdges) => {
    const filteredEdges = prevEdges.filter(
      (edge) => edge.source !== connection.source && edge.target !== connection.target
    );
    return [...filteredEdges, connection];
  });
};


  const handleDelete = (event, data) => {
        event.stopPropagation()
        setNodes((prevNodes) => prevNodes.filter((node) => node.id !== data.id));
    };
  
  function addNode(newNode){
    newNode['data']['handleDelete'] = handleDelete
    setNodes((prevNodes) => {
        const updatedNodes = [...prevNodes, { ...newNode }];
        return updatedNodes;
    });
  };

   // Function to handle node click and open panel
  const handleNodeClick = useCallback((event, node) => {
    const newNode = nodes.find(n => n.id === node.id);
    setSelectedNode(newNode);     
    setDisplayNodePanel(true)
  }, [nodes])

  function onElementClick (event, element) { console.log('click', element);}

  const updateNode = (updatedNode) => {
    setNodes((nds) =>
      nds.map((n) => (n.id === updatedNode.id ? updatedNode : n))
    );
    setSelectedNode(null); // Close the panel after updating
  };

  const onNodesChange = (changes) => {
    setNodes((prev) => applyNodeChanges(changes, prev));
  };
  // const onEdgesChange = (changes) => {
  //   setEdges((prevEdges) => {
  //     let updatedEdges = [...prevEdges];

  //     changes.forEach((change) => {
  //       const { type, edge } = change;
  //       if (type === 'add') {
  //         updatedEdges = updatedEdges.filter((existingEdge) => existingEdge.source !== edge.source);
  //         updatedEdges = applyEdgeChanges([change], updatedEdges);
  //       } else {
  //         updatedEdges = applyEdgeChanges([change], updatedEdges);
  //       }
  //     });

  //     return updatedEdges;
  //   });
  // };

const onEdgesChange = (changes) => {
  setEdges((prevEdges) => {
    const updatedEdges = [...prevEdges];

    changes.forEach((change) => {
      if (change.type === 'add') {
        const { source, target } = change.edge;

        // Remove all edges from the same source
        const filteredEdges = updatedEdges.filter((edge) => edge.source !== source);

        // Add the new edge
        return [...filteredEdges, change.edge];
      }

      // Handle other types of changes (delete, update, etc.)
      return applyEdgeChanges([change], updatedEdges);
    });
  });
};

  // const onEdgesChange = (changes) => {
  //   setEdges((prev) => applyEdgeChanges(changes, prev));
  // };

  const onConnectStart = useCallback((_, { nodeId, handleType }) => {
    setEdge({ source: nodeId, sourceHandle: handleType });
  }, []);
  // const onConnectEnd = useCallback((event) => {
  //   const targetNode = document.elementFromPoint(event.clientX, event.clientY).closest('.react-flow__node');
    
  //   if (targetNode) {
  //     const targetNodeId = targetNode.getAttribute('data-id');
  //     setEdges((eds) =>
  //       addEdge(
  //         {
  //           id: `${edge.source}-${targetNodeId}`,
  //           source: edge.source,
  //           target: targetNodeId,
  //           animated: true,
  //           markerEnd: {
  //             type: MarkerType.ArrowClosed,
  //           },
  //           type: 'straight',
  //           style: { stroke: '#333', strokeWidth: 2 },
  //         },
  //         eds
  //       )
  //     );
  //   }
  //   setEdge(null); // Reset the floating edge
  // }, [edge]);

 const onMouseMove = useCallback((event) => {
    if (!edge) return;
    setEdge((prev) => ({
      ...prev,
      targetX: event.clientX,
      targetY: event.clientY,
    }));
  }, [edge]);



function createBot(name, description){
  console.log('createBot '+JSON.stringify(nodes)+' and    '+JSON.stringify(edges))
  let payload = {name:name, description: description,
                    nodes: nodes,
                edges: edges
              }
  if(params.id === undefined){
      axiosClient.postRequest(constants.CHAT_BOT_URL, payload)
         .then(function (response) {
             // loadChatBots();    
            toast.success("Bot Created Successfully")    
            // window.location.href='/admin/chatbots'      
         }).catch((error) => {
             console.log(error?.response?.data?.error)
             if(error?.response?.data?.error){
              toast.error(error?.response?.data?.error)
             }else{
              toast.error('Unexpected error occured.... Please contact support')
             }
         });
  }else{

      axiosClient.patchRequest(constants.CHAT_BOT_URL+'/'+params.id, payload)
         .then(function (response) {
             // loadChatBots();    
            toast.success("Bot Updated Successfully")    
            // window.location.href='/admin/chatbots'      
         }).catch((error) => {
             console.log(error?.response?.data?.error)
             if(error?.response?.data?.error){
              toast.error(error?.response?.data?.error)
             }else{
              toast.error('Unexpected error occured.... Please contact support')
             }
         });
  }
}

function closeCreateWindow(){
  setSelectedNode(null)
  setDisplayNodePanel(false)
} 


  return (

<>

      <div class='header-body'>
          <div class="main-header" style={{justifyContent:'end'}}>
                <button class='ss-button' style={{float:'right'}} onClick={()=>setDisplayNodePanel(true)}>Add Node</button>
                <button class='ss-button' style={{float:'right'}} onClick={()=>setCreate(true)}>Create</button>
          </div>
      </div>
      <div class='main-body' style={{height:'100%', width:'100%',paddingLeft:'10px'}}>
        {displayNodePanel && (<NodePanel closeCreateWindow={closeCreateWindow} addNode={addNode} sbOptions={[]}
          updateNode={updateNode}  selectedNode={selectedNode} handleDelete={handleDelete}/>)}
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes} 
            onNodeClick={handleNodeClick}
          >

          <Background />
          <Controls />    
          </ReactFlow>          
         {create ?  <CreateChatBotWindow name={name} description={description} createBot={createBot} setCreate={setCreate}/> : ''}
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                    newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                    draggable pauseOnHover theme="colored"/>  
</>
  );
};

export default CreateChatBotForm;





