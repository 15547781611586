import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../../js/Axios';
import * as constants from '../../../../js/constants'
import BusinessCalenderLine from "./BusinessCalenderLine"
import BusinessCalenderListDisplayHeader from './BusinessCalenderListDisplayHeader'
import CreateBusinessCalenderForm from './CreateBusinessCalenderForm'
import {useNavigate} from "react-router-dom"

function BusinessCalendersListDisplay(){
	const primaryCheckBoxRef = useRef(null)
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(1000)	
	const [businessCalenderLine, setBusinessCalenderLine] = useState("")
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)
	const [checkboxState, setCheckboxState] = useState(false)
	const [showEditBusinessCalenderForm, setShowEditBusinessCalenderForm] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		loadBusinessHours();
		groupButtonsRef.current.style.visibility = 'hidden'
	},[])//use effect

function loadBusinessHours(){	
		axiosClient.getRequest(constants.BUSINESS_CALENDER_URL+'?pageNo='+pageNo+'&pageSize='+pageSize)
		.then(function (response) {
			let businessCalenderList = null;
			businessCalenderList = response.data.map((element, index) => (									
							<BusinessCalenderLine businessCalender= {element} onCheckboxClicked={onCheckboxClicked}/>
			));
			setBusinessCalenderLine((Object.keys(businessCalenderList).length === 0  ? 'No Records Found...' : businessCalenderList))
		}).catch(function (response) {
		    console.log(response);
		});	
}
function performAction(type){
	let payload = {'ids': ids}
//TODO: block becomes unblock
   	axiosClient.postRequest(constants.BUSINESS_CALENDER_URL+'delete', payload)
       .then(function (response) {
           loadBusinessHours();
		let elements = document.getElementsByClassName('business-line-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		}           
       }).catch((error) => {
           console.log(error)
       });      
}

function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('business-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked){
			idsTemp.push(id)
			if(event.target.checked){		
				groupButtonsRef.current.children[0].style.visibility = 'visible'
			}else{
				groupButtonsRef.current.children[0].style.visibility = 'hidden'
			}				
		}else{
		 const index = idsTemp.indexOf(id);
			if (index > -1) {
	  			idsTemp.splice(index, 1);
	  			if(idsTemp.length === 0){
	  				groupButtonsRef.current.children[0].style.visibility = 'hidden'
	  			}
			}
		}
	}
	setIds(idsTemp)
}
function onCheckboxClicked(id, value){
	let idsTemp = ids;
	if(value){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}		
}

function searchBusinessCalenders(event){
	let pageNo=0;
	let pageSize=10;	
	axiosClient.getRequest(constants.BUSINESS_CALENDER_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pageNo+'&pageSize='+pageSize)
	.then(function (response) {
			let businessCalenderList = null;
			businessCalenderList = response.data.map((element, index) => (					
							<BusinessCalenderLine businessCalender= {element} onCheckboxClicked={onCheckboxClicked}/>
			));
			setBusinessCalenderLine((Object.keys(businessCalenderList).length === 0  ? 'No Records Found...' : businessCalenderList))
	}).catch(function (response) {
	    console.log(response);
	});	
}

	return (
		<>
		   <BusinessCalenderListDisplayHeader searchBusinessCalenders={searchBusinessCalenders} groupButtonsRef={groupButtonsRef}
		   			performAction={performAction}/>
			<div class="main-body">
			   <div class="page-body" >
			   		<div class='container' style={{padding:'20px',paddingTop:'10px', minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
					   <table class='table'>
					      <thead>
					         <tr >
					            <td style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}><input type="checkbox" value="" onClick={onPrimaryCheckboxClicked} ref={primaryCheckBoxRef}/></td>
					            <td style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Name</td>
					            <td style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>is24Hour</td>
					            <td style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Timezone</td>
					         </tr>
					      </thead>
					      <tbody>
								{businessCalenderLine}
					      </tbody>
					   </table>
					</div>
				</div>
				</div>
		</>
 );

}
export default BusinessCalendersListDisplay;
