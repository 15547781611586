import React, { useState, useEffect } from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import '../css/ticket.css'
import TicketLine from "./TicketLine"
import CreateTicketForm from './CreateTicketForm'
import TicketListFilterBox from './TicketListFilterBox'
import TicketListDisplayHeader from './TicketListDisplayHeader'
import {Route, Link, Routes, useParams, useNavigate} from 'react-router-dom';

function TicketHistoryLine(props){
	const [history, setHistory] = useState(null)
	const [ticketLine, setTicketLine] = useState(null)
	const [showCreateTicketForm, setShowCreateTicketForm] = useState(false)
	const params = useParams();
	const navigate = useNavigate();


function ticketCreated(){
	return  <li>		        
		        <h5 class="fw-bold">Ticket Created</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Ticket Requested by  {(props.element.contact !== null) ? <a href={"/contacts/"+props.element.contact.id}>{props.element.from}</a> : props.element.from}
			    </p>
		    </li>	
}
function summaryUpdated(){
	return  <li>		        
		        <h5 class="fw-bold">Summary Updated</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Summary Updated by  {(props.element.user !== null) ? <a href={"/agents/"+props.element.user.id}>{props.element.fromId}</a> : props.element.fromId}
			    </p>
		    </li>	
}
function userAssigned(){
	return  <li>		        
		        <h5 class="fw-bold">User Assigned</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Agent changed by  {(props.element.user !== null) ? <a href={"/users/"+props.element.user.id}>{props.element.from}</a> : props.element.from}
			    </p>
		    </li>	
}

function teamAssigned(){
	return  <li>		        
		        <h5 class="fw-bold">Team Assigned</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Ticket Requested by  {(props.element.contact !== null) ? <a href={"/contacts/"+props.element.contact.id}>{props.element.from}</a> : props.element.from}
			    </p>
		    </li>	
}
function reporterCommented(){
	return  <li>		        
		        <h5 class="fw-bold">Comment Added</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Added a comment by Reporter {(props.element.contact !== null) ? <a href={"/contacts/"+props.element.contact.id}>{props.element.from}</a> : props.element.from}
			    </p>
		    </li>	
}
function userCommented(){
	return  <li>		        
		        <h5 class="fw-bold">Comment Added</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Added a comment by Agent {(props.element.user !== null) ? <a href={"/users/"+props.element.user.id}>{props.element.from}</a> : props.element.from}
			    </p>
			    <strong>Content:</strong>
			    <div dangerouslySetInnerHTML={{ __html: props.element.to }} style={{width:'99%', maxHeight:'300px', overflowY:'auto'}} class='no-list-style'/>
		    </li>	
}

function statusText(){
	if(props.element.to && props.element.to.toLowerCase() === 'closed')
		return 'Ticket Resolved by'
	if(props.element.from &&props.element.from.toLowerCase() === 'closed')
		 return 'Ticket Reopened by'
	return 'Status Updated by'
}
function statusUpdated(){
	return  <li>		        
		        <h5 class="fw-bold">Status Updated</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        {statusText()} {(props.element.user !== null) ? <a href={"/users/"+props.element.user.id}>{props.element.fromId}</a> : props.element.fromId} from <strong> {props.element.from}</strong> to <strong>{props.element.to}</strong>		        	
			    </p>
		    </li>	
}
function priorityUpdated(){
	return  <li>		        
		        <h5 class="fw-bold">Priority Updated</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Priority Updated by  {(props.element.user !== null) ? <a href={"/users/"+props.element.user.id}>{props.element.fromId}</a> : props.element.fromId} from <strong>{props.element.from}</strong> to <strong> {props.element.to}</strong>
			    </p>
		    </li>
}
function ticketCloned(){
	return  <li>		        
		        <h5 class="fw-bold">Ticket Created</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Ticket Requested by  {(props.element.contact !== null) ? <a href={"/contacts/"+props.element.contact.id}>{props.element.from}</a> : props.element.from} on {props.element.createdDate}
			    </p>
		    </li>
}
function commentDeleted(){
	return  <li>		        
		        <h5 class="fw-bold">Ticket Created</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Ticket Requested by  {(props.element.contact !== null) ? <a href={"/contacts/"+props.element.contact.id}>{props.element.from}</a> : props.element.from} on {props.element.createdDate}
			    </p>
		    </li>	
}
function ticketMerged(){
		return  <li>		        
		        <h5 class="fw-bold">Ticket Merged</h5>
		        <div class="time">{props.element.createdDate}</div>
		        <p>
			        Ticket Merged to <a href={"/tickets/"+props.element.toId}> #{props.element.toId}</a> by  {(props.element.user !== null) ? <a href={"/users/"+props.element.user.id}>{props.element.from}</a> : props.element.from} on {props.element.createdDate}
			    </p>
		    </li>
}
function historyType(){
	console.log('historyType '+props.element.type)
	if(props.element.type==='TICKET_CREATED'){
		return ticketCreated()
	}else if(props.element.type==='SUMMARY_UPDATED'){
		return summaryUpdated()
	}else if(props.element.type==='ASSIGN_USER'){
		return userAssigned()
	}else if(props.element.type==='ASSIGN_TEAM'){
		return teamAssigned()
	}else if(props.element.type==='REPORTER_COMMENTED'){
		return reporterCommented()
	}else if(props.element.type==='USER_COMMENTED'){
		return userCommented()
	}else if(props.element.type==='STATUS_UPDATED'){
		return statusUpdated()
	}else if(props.element.type==='PRIORITY_UPDATED'){
		return priorityUpdated()
	}else if(props.element.type==='CLONED'){
		return ticketCloned()
	}
	else if(props.element.type==='COMMENT_DELETED'){
		return commentDeleted()
	}else if(props.element.type==='TICKET_MERGED'){
		return ticketMerged()
	}
}

	return (
	<>
			{historyType()}
	</>
		);

	}
export default TicketHistoryLine;