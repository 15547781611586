import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import { usePermissions } from '../../PermissionsProvider';


function TeamListDisplayHeader(props){
  const { isActionAllowed } = usePermissions();
  const navigate = useNavigate(); 
  const [filterState, setFilterState] = useState('All Teams')
  const [sessionData, setSessionData] = useState(null)
  useEffect(() => {
    setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
  },[])//use effect

  function openTeam(id){
  	let path = constants.TEAM_URL_ABS +props.team.id ;
      navigate(path);
  }

  function toggleFilterBox(){
  	props.filterBoxRef.current.style.display = "block"
  }
  function createNewTeam(){
  	// navigate(constants.TEAM_URL_ABS+'new')
  	props.showCreateConactFormFun()
  }

return (
  <div class='header-body'>
	    <div class="main-header">
	    	<div style={{fontWeight:'bold',paddingLeft:'10px' }}>{filterState}</div>
     		 <div class="search-wrapper">
   					{<img src={require('../../../img/search.png')} alt=''/>}
   					<input placeholder="search here" class="search-input" onChange={props.searchTeams}/>
             </div>	          
	         <div class="header-right" ref={props.groupButtonsRef}>
	           {isActionAllowed('DELETE_TEAN') &&   (<button class='ss-button'  type="button" onClick={() => props.performAction('delete')}>Delete</button>	            )}
	         </div>
          { ((sessionData !== null && sessionData.isAdmin) || isActionAllowed('CREATE_AGENT')) ?   <button class='ss-button'  type="button" onClick={createNewTeam}>+ New Team</button> : ''}
	    </div>
  </div>
);

}

export default TeamListDisplayHeader;