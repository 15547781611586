import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
import {Route, Link, Routes, useParams} from 'react-router-dom';
import Tag from './Tag'
import Dropdown from 'react-bootstrap/Dropdown';
import '../css/new.css'
import DynamicForm from '../../CustomFields/js/DynamicForm'
import { usePermissions } from '../../PermissionsProvider';
import {ToastContainer, toast } from 'react-toastify';

function TicketLeftWindow(props){
  const { isActionAllowed, isMenuVisible } = usePermissions();
  // const [formData, setFormData] = useState({  });
  const [customFields, setCustomFields] = useState([]) 
  let status = ''
  let priority = ''
  let team = ''
  let assignee = null;
	const assigneeRef = useRef(null)
  const  [tags, setTags] = useState([])
  const assigneeDropdownRef = useRef(null)
  const teamRef = useRef(null)
  const teamDropdownRef = useRef(null)
  const priorityRef = useRef(null)
  const priorityDropdownRef = useRef(null)
  const statusRef = useRef(null)
  const statusDropdownRef = useRef(null)
  const [ticket, setTicket] = useState(null)

	useEffect(() => {
        setTicket(props.ticket)
        status = props.ticket.status
        priority = props.ticket.priority
        assignee = fetchAssigneeValue()
        team = (props.ticket.team == null? '' : props.ticket.team.name)
        statusRef.current.value = status
        if(!isActionAllowed('REOPEN_TICKET') && status === 'Closed')
          statusRef.current.disabled = true
        setPriorityFun(priority)
        assigneeRef.current.value = fetchAssigneeValue()
        if(!isActionAllowed('REASSIGN_TICKET') && assigneeRef.current.value !== '--')
          assigneeRef.current.disabled = true

        teamRef.current.value = (props.ticket.team == null? '--' : props.ticket.team.name)
        document.body.addEventListener('click', handleClickOnBody, true); 
        if(props.ticket.customFields){
          let jsonCustomFields = JSON.parse(props.ticket.customFields.replace(/'/g, '"'))          
          console.log('jsonCustomFields  '+jsonCustomFields)
          if(jsonCustomFields && jsonCustomFields !== null){
            setCustomFields(jsonCustomFields)
            // let formDataTemp =   jsonCustomFields.map((field) => ({
            //           id: field.id,
            //           name: field.name,
            //           formValues: field.formValues,
            //           type: field.type,
            //           apLabel: field.apLabel,
            //           values: field.values
            //         }))
            //  setFormData(formDataTemp) 
          }else{
            setCustomFields([])
            // setFormData({})
          }
        }
	},[])//use effect



function fetchAssigneeValue(){
  let ticketTemp = null;
  if(ticket === null)
      ticketTemp = props.ticket
  else
    ticketTemp = ticket;
  return   (ticketTemp.assignee !== null) ?
            ticketTemp.assignee.name.charAt(0).toUpperCase() + ticketTemp.assignee.name.slice(1) : '--';
}

function setPriorityFun(priority){
  let priorityColor = {low: '#28a745',
                       medium:'#007bff',
                       high: '#ff7400',
                       critical: '#dc3545',
                       escalated: 'red' }
  priorityRef.current.children[0].style.backgroundColor = priorityColor[priority.toLowerCase()]              
  priorityRef.current.children[1].innerHTML = priority  
}
function fetchValues(event,type){
  let dropdownRef = null
  let value = event.target.value
  console.log('value is '+value)
  event.target.innerHTML = value
  let ref = null
  if(type === 'user'){
    ref= assigneeRef
    dropdownRef = assigneeDropdownRef
  }else if (type === 'team'){
    ref=teamRef
    dropdownRef = teamDropdownRef
  }
  if(event.target.value.length < 3)
      return;
  dropdownRef.current.innerHTML = ''
  let url  = {'user':constants.USER_URL,'team':constants.TEAM_URL }
  axiosClient.getRequest(url[type]+'search_by_name?keyword='+event.target.value)
  .then(function (response) {  
      if(type === 'user' && isActionAllowed('ASSIGN_TO_AGENT')){
        addUnassignedToDropDown(null, event,type, dropdownRef)
      }          
      response.data.map((element, index) => (              
          addToDropDown(element, event,type, dropdownRef)
      ));
  }).catch(function (response) {
      console.log(response)
  });
}
  
function dropdownEditCompleteClicked(event,type){
  let ref = null
  let dropdownRef = null
  if(type ==='team'){
    if(event.target.innerHTML === team)
      return    
    ref = teamRef
    dropdownRef = teamDropdownRef.current
  }
  else if(type==='user'){
    if(event.target.innerHTML === assignee)
      return
    ref = assigneeRef
    dropdownRef=assigneeDropdownRef.current
    type='assignee'
  }

  let payload = { 'id': props.ticket.id}
  let id = null;
  payload[type] = {'id': event.target.name }
  if(event.target.name === 'Unassign'){
      if(assignee == '--')  
        return
      payload[type] = {'id': -1 } 
  }    
  if(type === 'assignee'){
    type = 'agent'

  }
  axiosClient.postRequest(constants.TICKET_URL+'assign_'+type,payload)
    .then(function (response) { 
    let value = null
    if(type === 'agent'){
      if(response.data.assignee === null)
        value = '--'
      else
        value=response.data.assignee.name
      assignee = value;
    }
    if(type === 'team'){
      if(response.data.team === null)
        value = '-'
      else
        value=response.data.team.name
    }
    ref.current.value = value 

    dropdownRef.innerHTML = ''
    dropdownRef.style.display = 'none'
  }).catch(function (response) {
      console.log(response)
  });
}
//hover colour TODO
function addToDropDown(element, event, type, dropdownRef){
      if(type === 'user' && !isActionAllowed('ASSIGN_TO_AGENT'))
        return

      let aTag = document.createElement('a')
      aTag.name=element.id
      aTag.innerHTML = element.name
      aTag.style.width = '250px'
      aTag.onclick = (event) => dropdownEditCompleteClicked(event, type)
      dropdownRef.current.appendChild(aTag)
      dropdownRef.current.style.display='block'
}
function addUnassignedToDropDown(element, event, type, dropdownRef){
      let aTag = document.createElement('a')
      aTag.name= 'Unassign'
      aTag.innerHTML = 'Unassign'
      aTag.style.width = '250px'
      aTag.onclick = (event) => dropdownEditCompleteClicked(event, type)
      dropdownRef.current.appendChild(aTag)
      dropdownRef.current.style.display='block'
}

function onSelectDropdown(event,type, value){
  console.log('onSelectDropdown '+event.target.outerHTML+'  '+value)
  let ref=null;
  let dropdownRef = null;
  let valueChanged = false
  if(type === 'status'){
    ref=statusRef
    dropdownRef = statusDropdownRef
    if(status !== value)
      valueChanged = true
    for (let field of customFields) {
      if (field.apMandatoryStatusChange === "true" && (!field.formValues || field.formValues.trim() === "")) {
        toast.error('Field '+field.apLabel+' must have a value to change the status')
        return false; 
      }
    }

    if(value === 'Closed'){
      for (let field of customFields) {
        if (field.apMandatoryResolution === "true" && (!field.formValues || field.formValues.trim() === "")) {
          toast.error('Field '+field.apLabel+' must have a value to resolve the ticket')
          return false; 
        }
      }  
    }
    

  }else if(type === 'priority'){
    ref=priorityRef
    dropdownRef = priorityDropdownRef
    if(priority !== value)
      valueChanged = true    
  }
  
  if(valueChanged){
      let payload = { 'id': props.ticket.id } 
      payload[type] =  value
      axiosClient.postRequest(constants.TICKET_URL+'update_'+type,payload)
        .then(function (response) { 
          if(type === 'status'){
              status = value 
              ref.current.value = value             
          }else if(type === 'priority'){
            priority = value
            setPriorityFun(value)              
          }                  
          dropdownRef.current.style.display = 'none' 
      }).catch(function (response) {
          console.log(response)
      });
  }else{
      dropdownRef.current.style.display = 'none'
      ref.current.value = value 
  }   
}



function handleClickOnBody(event){
  // return
  console.log('handleClickOnBody '+statusDropdownRef.current+' '+priority+' ')
  if(statusDropdownRef.current !== undefined && statusDropdownRef.current !== null)
    statusDropdownRef.current.style.display = 'none'
  if(priorityDropdownRef.current !== undefined && priorityDropdownRef.current !== null)
    priorityDropdownRef.current.style.display = 'none'
  if(assigneeDropdownRef.current !== undefined && assigneeDropdownRef.current !== null)
    assigneeDropdownRef.current.style.display = 'none'
  if(teamDropdownRef.current !== undefined && teamDropdownRef.current !== null)
    teamDropdownRef.current.style.display = 'none'
  if(assigneeRef.current !== undefined && assigneeRef.current !== null){
    console.log('assignee '+assignee)
    assigneeRef.current.value = assignee
  }
  
  if(teamRef.current !== undefined && teamRef.current !== null && teamRef.current.value === '')
     teamRef.current.value = '--'
}

function removeTag(id){

}
function displayDropdown(event){
  event.target.parentElement.children[1].style.display='block'
}
function handleSelectChange(event,ref){
  event.preventDefault();
  ref.current.style.display = 'block'
}

function agentClicked(event){
  if(event.target.value === '--')
    event.target.value = ''
}

function assignToMe(){
    axiosClient.postRequest(constants.TICKET_URL+ticket.id+'/assign_to_me')
        .then(function (response) { 
          setTicket(response.data)
            assigneeRef.current.value = response.data.assignee.name
      }).catch(function (response) {
          console.log(response)
      });
}


function teamClicked(event){
  if(event.target.value === '--')
    event.target.value = ''
}


const handleInputChange = (e, index) => {
  const { value } = e.target;
  setCustomFields((prevFields) =>
    prevFields.map((field, idx) => {
      if (idx === index) {
        const originalValue = field.originalValue ?? field.formValues;
        return { ...field, formValues: value, isEdited: true, originalValue };
      }
      return field;
    })
  );
};



function formDataToJson(formData) {
  console.log('formDataToJson  '+JSON.stringify(formData))
    const obj=[]
    formData.forEach((value, key) => {
        obj.push(value)      
    });
    return JSON.stringify(obj);
}

const handleUpdateValue = (index) => {
  const updatedFields = [...customFields];
  updatedFields[index] = { 
    ...updatedFields[index], 
    isEdited: false, 
    originalValue: undefined 
  };

  let payload={
    customFields:  formDataToJson(updatedFields)
  }
  axiosClient
    .patchRequest(
      `${constants.TICKET_URL}update_custom_field/${props.ticket.id}`,
       payload 
    )
    .then((response) => {
      console.log("Successfully updated custom fields:", response.data);
      setCustomFields(updatedFields);
    })
    .catch((error) => {
      console.error("Error updating custom fields:", error);
    });
};


// "[{'id':'7','name':'Tickets','formValues':'','type':'textbox','apLabel':'Check','cpLabel':'Check','values':'','apMandatoryStatusChange':'false','apMandatorySubmission':'false'}]"


const handleDiscardValue = (index) => {
  setCustomFields((prevFields) =>
    prevFields.map((field, idx) => {
      if (idx === index) {
        return { ...field, formValues: field.originalValue, isEdited: false, originalValue: undefined };
      }
      return field;
    })
  );
};




function slaDueDates(){
 return 
    <>
                 <tr>
                    <td style={{fontFamily:'Lato',fontWeight:'500'}}>First Response Due</td>
                    <td>{props.ticket.first_response_due_date}</td>
                 </tr>
                 <tr>
                    <td style={{fontFamily:'Lato',fontWeight:'500'}}>Resolution Due</td>
                    <td>{props.ticket.resolution_due_date}</td>
                 </tr> 
    </>
}
  return (
        <div class="Ticket-Left-Box">
          <a href="javascript:void(0)" class="create-closebtn" onClick={props.closeTicketLeftBox} style={{right:'0px'}}>&times;</a>
           <div class="content">
            <div class="table_1">
              <table>
               <tr>
                  <td  class='titillium-web-regular'>Ticket Id</td>
                  <td><strong>{props.ticket.ticket_number}</strong></td>
               </tr>
               <tr>
                    <td  class='titillium-web-regular'>Status</td>
                    <td>
                        <div style={{position: 'relative'}}> 
                          <input type='text' disabled={!isActionAllowed('CHANGE_TICKET_STATUS')}   style={{padding:'4px', width: '90%',minWidth:'150px', border:'none', color:'black'}} onChange={(event) => handleSelectChange(event, statusDropdownRef,'status')} onClick={(event) => handleSelectChange(event, statusDropdownRef, 'status')} ref={statusRef}/>
                          <div style = {{width:'250px',position: 'absolute', display: 'none'}} class='display-ticket-dropdown-content' ref={statusDropdownRef}>
                            <a onClick={(event) =>onSelectDropdown(event,'status', 'Open')} style={{width:'250px',cursor: 'pointer', padding:'5px'}}>Open</a>
                            <a onClick={(event) =>onSelectDropdown(event,'status', 'Blocked')} style={{width:'250px',cursor: 'pointer', padding:'5px'}}>Blocked</a>
                            <a onClick={(event) =>onSelectDropdown(event,'status', 'InProgress')} style={{width:'250px',cursor: 'pointer', padding:'5px'}}>InProgress</a>
                            <a onClick={(event) =>onSelectDropdown(event,'status', 'Need Information')} style={{width:'250px',cursor: 'pointer' , padding:'5px'}}>Need Information</a>
                            {isActionAllowed('CLOSE_TICKET') && (<a onClick={(event) =>onSelectDropdown(event,'status', 'Closed')} style={{width:'250px',cursor: 'pointer' , padding:'5px'}}>Closed</a>)}
                          </div>
                          <span class="spinner-border" role="status" style={{height:'15px', width:'15px',display: 'none', opacity:'0.4'}} />
                        </div>
                    </td>
               </tr>
               <tr>
               <td  class='titillium-web-regular'>Priority</td>
                    <td>
                        <div style={{position: 'relative'}}>                           
                          <div contenteditable={!isActionAllowed('CHANGE_TICKET_PRIORITY')}  style={{padding:'4px',width: '90%',minWidth:'150px', border:'none', color: '#4c4f56'}} onChange={(event) => handleSelectChange(event, priorityDropdownRef,'priority')} onClick={(event) => handleSelectChange(event, priorityDropdownRef,'priority')} ref={priorityRef}><span class='priority-div-filter' style={{backgroundColor: '#28a745' , border:'1px solid #28a745'}}/> <span/></div>
                          <div style = {{overflowY: 'hidden', fontFamily: '"Myriad Pro","Arial",sans', borderRadius: '10px', width:'250px',position: 'absolute', display: 'none'}} class='display-ticket-dropdown-content' ref={priorityDropdownRef}>
                              <a onClick={(event) =>onSelectDropdown(event,'priority','Low')} style={{cursor: 'pointer'}}><span class='priority-div-filter' style={{backgroundColor: '#28a745' , border:'1px solid #28a745'}}/> <label for ='priority-low'><span style={{fontWeight:'500',fontSize:'16px', cursor:'pointer'}}>Low</span></label></a>
                              <a onClick={(event) =>onSelectDropdown(event,'priority','Medium')} style={{cursor: 'pointer'}}><span class='priority-div-filter' style={{backgroundColor: '#007bff' , border:'1px solid #007bff'}}/> <label for ='priority-medium'><span style={{fontWeight:'500',fontSize:'16px', cursor:'pointer'}}>Medium</span></label></a>
                              <a onClick={(event) =>onSelectDropdown(event,'priority','High')} style={{cursor: 'pointer'}}><span class='priority-div-filter' style={{backgroundColor: '#ff7400' , border:'1px solid #ff7400'}}/> <label for ='priority-high'><span style={{fontWeight:'500',fontSize:'16px', cursor:'pointer'}}>High</span></label></a>
                              <a onClick={(event) =>onSelectDropdown(event,'priority','Critical')} style={{cursor: 'pointer'}}><span class='priority-div-filter' style={{backgroundColor: '#dc3545' , border:'1px solid #dc3545'}}/> <label for ='priority-critical'><span style={{fontWeight:'500',fontSize:'16px', cursor:'pointer'}}>Critical</span></label></a>
                             {isActionAllowed('ESCALATE_TICKET') && (<a onClick={(event) =>onSelectDropdown(event,'priority', 'Escalated')} style={{cursor: 'pointer'}}><span class='priority-div-filter' style={{backgroundColor: 'red' , border:'1px solid red'}}/> <label for ='priority-escalated'><span style={{fontWeight:'500',fontSize:'16px', cursor:'pointer'}}>Escalated</span></label></a>) }
                          </div>
                          <span class="spinner-border" role="status" style={{height:'15px', width:'15px',display: 'none', opacity:'0.4'}} />
                        </div>
                    </td>
               </tr>
               <tr>
                    <td  class='titillium-web-regular'>Assignee</td>
                    <td>
                        <div style={{position: 'relative'}}>                             
                            <input type='text'  style={{padding:'4px',width: '90%',minWidth:'150px', border:'none', color: '#4c4f56'}} ref={assigneeRef} onChange={(event)=>fetchValues(event,'user')} onClick={agentClicked}  disabled={!(isActionAllowed('ASSIGN_TO_SELF') ||isActionAllowed('ASSIGN_TO_AGENT')) }/>
                            <div style= {{display: 'none',width: '90%',minWidth:'150px'}} ref={assigneeDropdownRef} class='display-ticket-dropdown-content'>
                            </div>
                            <span class="spinner-border" role="status" style={{height:'15px', width:'15px',display: 'none', opacity:'0.4'}} />
                            {
                              (isActionAllowed('ASSIGN_TO_SELF') || isActionAllowed('ASSIGN_TO_AGENT')) &&
                              (<a href='#' style={{textDecoration: 'none', textDecoration:'none', fontSize:'12px'}} onClick={assignToMe}>Assign to Me</a>
                            )}
                        </div>
                    </td>
               </tr>
               <tr>
               <td  class='titillium-web-regular'>Team</td>
                  <td>
                      <div style={{position: 'relative'}}> 
                          <div><input type='text'   style={{padding:'4px',width: '90%',minWidth:'150px', border:'none', color: '#4c4f56'}} onChange={(event)=>fetchValues(event,'team')} onClick={(event)=>fetchValues(event,'team')} ref={teamRef} onClick={teamClicked}/></div>
                          <div style= {{display: 'none',width: '90%',minWidth:'150px'}} ref={teamDropdownRef} class='display-ticket-dropdown-content'/>
                      </div>
                  </td>                    
               </tr>
               <tr>
               <td  class='titillium-web-regular'>Reopened</td>
               <td> <div style={{width:'75px', backgroundColor: (props.ticket.is_reopened === 'true') ? 'rgb(255, 116, 0)' : '#28a745',textAlign:'center', color:'white',borderRadius:'5px' }}>{ props.ticket.is_reopened === 'true' ? 'Yes' : 'No' } </div></td>
               </tr>               
               <tr>
                <td  class='titillium-web-regular'>First Response:</td>
                <td> <span style={{color: '#4c4f56'}}>{props.ticket.first_response === 'true' ? 'Yes' : 'No'}</span></td>
               </tr>
               <tr>
                <td  class='titillium-web-regular'>Created At:</td>
                <td> <span style={{color: '#4c4f56'}}>{props.ticket.created_date }</span></td>
               </tr>               

               { (props.ticket.first_response_due_date !== 'null' && props.ticket.first_response_due_date !== '') ?
                 slaDueDates() : ''
               }

               <tr>
                  <td style={{width:'150px', display: 'inline-block'}}  class='titillium-web-regular'>First Response At</td>
                  <td>{props.ticket.first_response_time === '' ? '--' : props.ticket.first_response_time}</td>
               </tr>
               <tr>
                  <td  class='titillium-web-regular'>Resolved At</td>
                  <td>{props.ticket.resolution_time === '' ? '--' : props.ticket.resolution_time}</td>
               </tr>
               {customFields.map((field, index) => (
                  <tr key={index}>
                    <td>{field['apLabel']}</td>
                      <td>
                        {field.apEditable === 'true' || field.apMandatoryStatusChange === 'true' || field.apMandatoryResolution === 'true' ? (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <input
                              type="text"
                              value={field.formValues || ""}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            {field.isEdited && ( // Show the button only when the value is edited
                              <>
                                <button onClick={() => handleUpdateValue(index)} style={{border:'none'}}>✅</button>
                                <button onClick={() => handleDiscardValue(index)} style={{border:'none'}}>❌</button>
                              </>
                            )}
                          </div>
                        ) : field.type === 'checkbox' ? (
                          field.formValues ? "True" : "False"
                        ) : (
                          field.formValues
                        )}
                      </td>
                  </tr>
                ))}
            </table>            
          </div>            
        </div>
              <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>            
      </div>
		)
}



export default TicketLeftWindow;