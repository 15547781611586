  import React from "react"
  import {useState, setState, useRef, useEffect, useContext} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  import '../../../css/main.css'
  import ProfilePicture from  '../../ProfilePicture/ProfilePicture'
  import SocketContext from '../../SocketContext'
  import CreateContactForm from '../../Contact/js/CreateContactForm'


  function ChatLeftWindow(props){
      console.log('ChatLeftWindow '+props.agentImageUrl)
      const [clickedProfileId, setClickedProfileId] = useState(-1)
      const [chatProfileData, setChatProfileData] = useState(-1)
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)      
      
      const [filterState, setFilterState] = useState('My Chats')
      const chatFilterRef = useRef(null)
      const [pageNo, setPageNo] = useState(0)
      const [pageSize, setPageSize] = useState(25)  
      const [showContactForm, setShowContactForm] = useState(false)
      const chatIdRef = useRef(null)
      const contactRef = useRef(null)
      const navigate = useNavigate();
      function chatProfileClicked(id){
        setClickedProfileId(id)
      }



function createContact(chatId){
  chatIdRef.current = chatId
  setShowContactForm(true)
}

function closeCreateWindow(value){
  console.log('closeCreateWindow '+ JSON.stringify(value))
  setShowContactForm(false)
  if(value){
    let payload={chatId: chatIdRef.current, contactId: contactRef.current.id}
      try {
        axiosClient.postRequest(constants.CHAT_URL+'assign_contact',payload)
        .then(function (response) {
            props.setChats(prevChats =>
                prevChats.map(chat =>
                  chat.id === chatIdRef.current
                    ? { ...chat, contactName: contactRef.current.name, contactId: contactRef.current.id }
                    : chat
                )
              );
        }).catch(function (response) {
            console.log(response);
        }); 
      } catch (error) {
          console.error("Error fetching chats:", error);
          // setLoading(false);
      }
  }
}
function selectFilter(type, filter ){

  try {
        axiosClient.getRequest(constants.CHAT_URL+'filter_chats?type='+type+'&pageNo='+pageNo+'&pageSize='+pageSize)
        .then(function (response) {
              props.setChats(response.data);
              hideChatMenu()
              setFilterState(filter)
        }).catch(function (response) {
            console.log(response);
        }); 
  } catch (error) {
      console.error("Error fetching chats:", error);
      // setLoading(false);
  }
}

function searchChats(){

}
function displayChatMenu(){
  chatFilterRef.current.style.display = 'block'
}

function hideChatMenu(){
  chatFilterRef.current.style.display = 'none'
}


      return( 
            <div className="chat-left-box">
                  <div style={{marginTop: '20px', display: 'flex'}}>
                      <div className="chat-profile-img">
                        {props.agentImageUrl !== 'null'? (
                          <img src={props.agentImageUrl} alt="Profile" />
                        ) : (
                          <ProfilePicture name={props.name} />
                        )}
                      </div>
                      <div className="chat-profile-details">
                        <h2 className="blue-color" style={{color: 'black'}}>{props.name}</h2>                                            
                      </div>
                  </div>


                  <div className="chat-search-wrapper">
                    <div >
                      <input placeholder="search here" className="search" onChange={searchChats}/>
                    </div>
                  </div>



                  <div class="chats-header-dropdown" onClick={displayChatMenu} onMouseLeave = {hideChatMenu}>
                  <a class="btn-icon"  style={{textDecoration: 'none', color: 'black'}}>
                    <img src={require('../../../img/dots.png')} alt='' style={{rotate: "90deg", height: "15px", cursor:'pointer' }}/> 
                    <span style={{textDecoration: 'none', color: 'black',fontWeight:'500'}}>{ filterState }</span>
                  </a>        
                  <div>
                      <div id="chats-header-menu" style={{display: "none"}} onClick={displayChatMenu} ref={chatFilterRef}>
                        <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('mychats', 'My Chats') }>My Chats</a>  
                        <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('all', 'All Chats') }>All Chats</a>
                        <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('unresolved', 'Unresolved Chats') }>Unresolved Chats</a>
                        <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('resolved', 'Resolved Chats') }>Resolved Tickets</a>               
                        <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('day', 'Created Last One day') }>Created Last One day</a>
                        <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('week', 'Created Last One week') }>Created Last One week</a>
                        <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('month', 'Created Last One Month') }>Created Last One Month</a>
                      </div>
                  </div>
                  </div>       

                  <div>
                    {
                      props.chats.length === 0 ? (
                        <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '18px', color: '#888' }}>
                          No conversations found
                        </div>
                      ) : (
                        props.chats.map((chat, index) => (
                          <div className="chat-profile" key={chat.unassignedChatId} style={{ marginTop: '20px', cursor:'pointer' }} onClick={()=>props.displayChatsInMiddleBox(chat.conversationId)}>
                            <div className="chat-profile-img">
                                  {chat.imageName !== 'null'? (
                                    <img src={chat.imageName} alt="Profile" />
                                  ) : (
                                    <ProfilePicture name={chat.contactName ? chat.contactName : 'U'} />
                                  )}
                            </div>
                            <div className="chat-profile-details">
                              <h2 className="blue-color" style={{ color: 'black' }}>
                                {chat.contactName !== 'null' ? <span style={{fontWeight:'500', maxWidth:'150px',display:'inline-block',textOverflow:'ellipsis' ,whiteSpace:'nowrap', overflow:'hidden'}} onClick={()=>navigate(constants.CONTACT_URL_ABS+chat.contactId)}>{ chat.contactName }</span> : 
                                  <>
                                    <span onClick={(e) => {
                                          e.stopPropagation();
                                          createContact(chat.id);
                                        }} style={{textDecoration:'underline'}}>Unknown</span>                                    
                                  </>
                                }
                              </h2>
                              <p style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'200px' }}>{(chat.content ? decodeURIComponent(chat.content).replace("ss_quote","\"")  : null) || 'No recent message.'}</p>
                            </div>
                            <div class='chat-time'>
                              <p>{new Date(chat.timestamp).toLocaleTimeString()}</p>
                              {chat.unreadCount ? <span></span> : ''}
                            </div>
                          </div>
                        ))
                      )
                    }
                </div>
                {showContactForm && <CreateContactForm  chatIdRef = {chatIdRef} 
                          closeCreateWindow={closeCreateWindow} contactRef={contactRef}/> }
            </div>
              
          )

  }

  export default ChatLeftWindow;