import React, {useRef} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'


function Attachment(props){
	const imageRef = useRef(null)

function remove(){
	console.log('remove '+props.index+' '+props.source)
	if(props.source === 'create')
		return props.removeAttachmentFromEdit(props.index)
	let id = props.attachment_id
	if(id === undefined)	
		id = props.index
	props.removeAttachmentFromEdit(id)
}

function downloadAttachment(event){
	if(props.isEditing === true)
		return
	console.log('downloadAttachment')
	try{
		let type = 'chat'
		if(props.source === 'comment')
			type='Tickets'
		axiosClient.getRequest(constants.ATTACHMENT_URL+props.attachment_id+'?type='+type)
	        .then(function (response) {
		      const link = document.createElement('a');
		      link.href = response.data.link;
		      link.setAttribute('download', `${props.name}`);
		      document.body.appendChild(link);
		      link.click();
		      document.body.removeChild(link);
	        }).catch((error) => {
	        	console.log('Download Attachment error')
	      });
	}finally{
		console.log('finally')
	}
}
function fetchAttachmentSize(){
	if(isNaN(props.size))
		return <div>Unknown</div>
	var size1 = parseInt(props.size/1000)
	if(size1 === 0){
		return props.size+' B' 
	}
	return size1+' kb'
}
function renderFileIcon(){
	let fileName = props.name
	let extension =  (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : undefined;
	console.log('extension '+extension)
	if(!extension)
		return <img src={require('../../../img/file-icon.png')} class="attachment-file-icon"/>	
	if(extension[extension.length-1] === 'pdf')
		return <img src={require('../../../img/pdf-icon.png')}  class="attachment-file-icon"/>
		if(extension[extension.length-1] === 'csv')
		return <img src={require('../../../img/csv-icon.png')}  class="attachment-file-icon"/>	
	if(extension[extension.length-1] === 'jpg' || extension[extension.length-1] === 'jpeg' )
		return <img src={require('../../../img/jpg-icon.png')}  class="attachment-file-icon"/>	
	if(extension[extension.length-1] === 'doc' || extension[extension.length-1] === 'docx')
		return <img src={require('../../../img/word-icon.png')} class="attachment-file-icon"/>	
	return <img src={require('../../../img/file-icon.png')}     class="attachment-file-icon"/>	
}


  return (
		<div class='attachment-outline' onClick={downloadAttachment}>
		  <div>{renderFileIcon()}</div>
		  <div class="attachment-name">
		  	<div style={{fontSize:'14px',color:'black',fontWeight:'500'}}>{props.name}</div>
		  	<div style={{fontSize:'12px'}}>{fetchAttachmentSize()}  </div>
		  </div>
		  <div>
			 {
				props.isEditing === true ? 
				 <div onClick={remove} ><a  href="javascript:void(0)" class="attachment-closebtn">&times;</a></div>
				: <img src={require('../../../img/download.png')} class="attachment-download-icon"/>
			 }
		 </div>
		</div>
    )
}

export default Attachment;