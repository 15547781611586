import React from "react"
import {useState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import { toast, ToastContainer } from 'react-toastify';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import SupportEmailLine from './SupportEmailLine'

function ChangeSupportEmail(props){	
	const errorRef = useRef(null)
	const nameRef = useRef(null)
	const replyToRef = useRef(null)
	const disableRef = useRef(null)
	const emailInputRef = useRef(null)	
	const teamRef=useRef(null)
	const [supportEmail, setSupportEmail] = useState(null)
	useEffect(()=>{
		console.log('ChangeSupportEmail useEffect '+ props.supportEmailIdRef.current)
		if(props.supportEmailIdRef.current !== undefined){
			fillEditForm()
		}
		// emailInputRef.current.value=props.supportEmail.replaceAll('.supportspoc.com','')
	})

function fillEditForm(){
		axiosClient.getRequest(constants.ACCOUNT_URL+'support_email/'+props.supportEmailIdRef.current)
		.then(function (response) {
			setSupportEmail(response.data.supportEmail)
			nameRef.current.value = response.data.friendlyName
			emailInputRef.current.value = response.data.email.replaceAll('.supportspoc.com', '')
			replyToRef.current.value = response.data.replyToEmail === 'null' ? '' : response.data.replyToEmail
			teamRef.current.value = response.data.team !== null ? response.data.team.name : ''
			disableRef.current.checked = !response.data.isActive
		}).catch(function (response) {
		    console.log(response);
		});			
}
function validateEmail(email){
	// var emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
	console.log('validateEmail '+email)
  var emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;  
  if(email.length > 330)
    return false;
  return (email.match(emailReg) !== null)
}
function changeSupportEmail(event){
	console.log('ChangeSupportEmail '+(props.supportEmailIdRef.current === undefined))
	event.preventDefault()
	errorRef.current.innerHTML = ''
	if(emailInputRef.current.value === null || emailInputRef.current.value.length===0)
	{
		errorRef.current.innerHTML='Please enter a valid email id'
		return
	}
	let email  = emailInputRef.current.value+'.supportspoc.oom';
	if(!validateEmail(email)){
		console.log('error')
		errorRef.current.innerHTML='Please enter a valid support email id'
		return
	}
	if(!validateEmail(replyToRef.current.value)){
		console.log('error')
		errorRef.current.innerHTML='Please enter a valid From email id'
		return
	}
	let payload={'email': emailInputRef.current.value+".supportspoc.com", 
				 'friendlyName': nameRef.current.value,
				 'teamId' : teamRef.current.children[2].innerHTML,
				 'isActive' : !disableRef.current.checked,
				 'replyToEmail': replyToRef.current.value
				}
				console.log('payload is '+ JSON.stringify(payload))
	if(props.supportEmailIdRef.current === undefined){
		axiosClient.postRequest(constants.ACCOUNT_URL+'support_email', payload)
			.then(function (response) {				
				toast.success('Support Email created successfully')
				props.closeChangeEmailWindow(true)
			}).catch(function (response) {
			    console.log(response);
			    toast.error('Unable to process the request. Please try again after sometime')
			})
	}else{
		axiosClient.patchRequest(constants.ACCOUNT_URL+'support_email/'+props.supportEmailIdRef.current, payload)
			.then(function (response) {				
				toast.success('Support Email updated successfully')
			}).catch(function (response) {
			    console.log(response);
			    toast.error('Unable to process the request. Please try again after sometime')
			})
	}
}

function onSelectDropdown(event, id, name){
	teamRef.current.children[0].value = name
    teamRef.current.children[1].innerHTML = ''
    teamRef.current.children[2].value = id
    teamRef.current.children[1].style.display = 'none'     
}

 function addChildToDropdown(element, event, type){
      let aTag = document.createElement('a')
      aTag.name=element.id
      aTag.innerHTML = element.name
      aTag.addEventListener('click', function(event){
              onSelectDropdown(event,element.id,element.name);
           });
      event.target.parentElement.children[1].appendChild(aTag)
      event.target.parentElement.children[1].style.display = 'block'
}

function handleTeam(event){   
      if(event.target.value.length < 3)
        return
      axiosClient.getRequest(constants.TEAM_URL+"search_by_name?keyword="+event.target.value)
      .then(function (response) {
          response.data.forEach((element, index)=>{             
              addChildToDropdown(element,event, "team")
          })
      }).catch(function (response) {
          console.log(response);
      });
}


	return(	
		<>
			<div class="create-wrapper">      	      	
	          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
	          		<a href="javascript:void(0)" class="create-closebtn" onClick={props.closeChangeEmailWindow}>&times;</a>
					<div style={{fontSize:'20px', fontWeight: '800', margin:'auto',display: 'table',color: '#044caf'}}> Support Email </div>
					<form submit={changeSupportEmail}>
						<div style={{ marginTop: "3%"}} class='create-form-label'>Name:</div>
              			<div><input  type="text" name="name"  style={{width: "90%", outline: 'none' }}  required   maxlength='320' ref={nameRef}/> </div>
						<div style={{ marginTop: "3%"}} class='create-form-label'>Support Email:<p class='red-color'>*</p></div>
              			<div><input  type="email" name="email"  style={{width: "70%", outline: 'none' }}  required  ref={emailInputRef} maxlength='320'/><span style={{fontSize:'16px',fontWeight:'600'}}>.supportspoc.com</span> </div>
						<div style={{ marginTop: "3%"}} class='create-form-label'>From Email:</div>
              			<div><input  type="email" name="reply_to"  style={{width: "90%", outline: 'none' }}   maxlength='320' ref={replyToRef}/> </div>
              			<div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Team:</div>
				        <div style={{position:'relative' }} ref={teamRef}>
				          <input type="text"  style={{width: "90%", border: '2px solid #aaa'}} onChange={handleTeam} name="team"/>
				          <div style={{display: 'none', position: 'absolute'}} class='create-ticket-dropdown-content'/>
				          <input style= {{display: 'none'}} name = 'team-hidden-input' />
				        </div>
              			<div style={{ marginTop: "3%"}} class='create-form-label'>Disable:
              			<input  type="checkbox" name="disable"    maxlength='320' ref={disableRef}/> </div>

						<div>							
							<button class='ss-button' style={{marginTop:'10%', marginLeft:'30%', padding:'5px 10px',width:'150px',display:'inline-block' }} onClick={changeSupportEmail} >
							{(props.supportEmailIdRef.current === undefined) ? 'Create Email' : 'Update Email' }</button>
						</div>
					</form>					
					<div ref={errorRef} style={{fontSize:'16px', fontWeight:'600',color:'red'}}/>
				</div>					
			</div>		
		<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>		
	</>
		 )
}

export default ChangeSupportEmail;