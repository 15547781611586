import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { usePermissions } from '../../PermissionsProvider';

function UserLine(props){
	const { isActionAllowed } = usePermissions();
	const navigate = useNavigate(); 
	const dropdownRef = useRef(null)
 	const [sessionData, setSessionData] = useState(null)

  useEffect(()=>{   	
    setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
    document.body.addEventListener('click', handleClickOnBody, true); 
   
  },[])

function openUser(event){
	event.preventDefault()
	if(sessionData.isAdmin || checkEditPermission())
		props.openUser(props.user.id)
    
}

function checkEditPermission(){
	if(isActionAllowed('EDIT_AGENT'))
		return true;
	return false
}
function capitalizeFirstLetter() {
	if(props.user.role === null || props.user.role === undefined || props.user.role === 'null')
		return 'Agent'
    return props.user.role.charAt(0).toUpperCase() + props.user.role.slice(1);
}
function fetchWorkPhones(){	
	let workPhone = '';
	if(props.user.workPhone !== '' && props.user.workPhone !== 'null')
		workPhone = workPhone  + props.user.workPhone;
	if(props.user.workPhone1 !== '' && props.user.workPhone1 !== 'null')
		workPhone = workPhone+','+props.user.workPhone1;
	if(props.user.workPhone2 !== '' && props.user.workPhone2 !== 'null')
		workPhone = workPhone+','+props.user.workPhone2;
	if(workPhone === '')
		workPhone = '--'
	return workPhone;

}
function checkBoxClicked(event){
	props.checkBoxClicked(props.user.id, event.target.checked)
}
function handleClickOnBody(event) {
	if(dropdownRef.current !== null)
		dropdownRef.current.style.display = 'none'
}
const popoverBottom = (
  <Popover id="popover-positioned-bottom" title="Popover bottom" style={{padding:'10px'}}>
    <div style={{cursor:'pointer', padding:'5px', fontWeight:'600'}} onClick={(event) => resendActivationLink(event)}>Send Activation Link</div>   
  </Popover>
);
function resendActivationLink(event){
	event.preventDefault()
 	props.resendActivationLink(props.user.id)
 	document.getElementById('popover-positioned-bottom').style.display='none'
}
return (
	        <tr>
	         { (sessionData !== null && sessionData.isAdmin) ?   
	         	<th scope="row" class="ps-4">
             		<div class="form-check font-size-16"><input type="checkbox" class="form-check-input user-line-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.user.id} style={{border:'1px solid #A9A9A9'}}/></div>
          		</th> 
	         	: '' }
	            <th onClick = {openUser} style={{minWidth: '25px', minHeight: '25px' }} class='add-ellipsis'>
		            {	props.user.image === 'null' ? 
		            		<ProfilePicture name={props.user.name} />
		            		: <img class="list-image"  src={ props.user.image} /> 
		            }
	            {(sessionData !== null && sessionData.isAdmin || checkEditPermission())	? <a href="" style = {{textDecoration: "none", marginLeft: '10px',fontWeight:'800',fonFamily:'Helvetica'}}>{props.user.name}</a> : <span style = {{textDecoration: "none", marginLeft: '10px',fontWeight:'500',fonFamily:'Helvetica'}}>props.user.name</span>}
	            </th>
	            <td style = {{width: '200px',overflow: 'hidden',whiteSpace: 'nowrap', textOverflow:'ellipsis'}} onClick = {openUser}>
						<a href="" style = {{textDecoration: "none",color:'black'}}>{props.user.email}</a>
	            </td>
	            <td style = {{width:'15%',overflow: 'hidden',whiteSpace: 'nowrap', textOverflow:'ellipsis'}}>
					{props.user['mobile_number'] === 'null' ? '-' : props.user.mobile_number}
	            </td>
	            <td>{fetchWorkPhones()}</td>
	            <td style = {{width: "18%"}}>{capitalizeFirstLetter()}</td>
	            <td >{props.user.email_verified === 'true' ? 'Yes' : 'No'}</td>
	            { (sessionData !== null && sessionData.isAdmin) ?  
		            <td style={{width:'100px'}}>
		            	{
		            	  props.user.email_verified === 'true' ? '' :		            	
						    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBottom} >
						      <img src={require('../../../img/dots.png')} alt='' style={{height:'15px', width:'15px', cursor:'pointer'}} id='overlay-trigger-dots'/>
						    </OverlayTrigger>
		            	}
		            </td>
	            : '' }
	         </tr>
            );

}

export default UserLine;