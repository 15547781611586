// socket.js
import { io } from 'socket.io-client';


const URL = process.env.REACT_APP_NODE_ENV === 'production' 
		  ? 'wss://api.supportspoc.com' 
		  : process.env.REACT_APP_NODE_ENV === 'staging' 
		  ? 'wss://api.support-spoc.com' 
		  : 'ws://localhost';
let token = localStorage.getItem('id_token');
// const socket = new io(auth: { 'token':  token})
const socket = io(URL, {auth: (cb) => {
					    cb({
					      token: token
					    });
					  }
					})
.connect(URL, {
    'reconnection': true,
    'reconnectionDelay': 1000,
    'reconnectionDelayMax' : 5000,
    'reconnectionAttempts': 5000
});
// globalSocket = socket
socket.on('connect', onConnect);
socket.on('disconnect', onDisconnect);
socket.on('message', onMessage);

function onMessage(message){

}
function onConnect(){
	console.log('onConnect')		    
	let session = JSON.parse(sessionStorage.getItem('ss_sess'))
	if(session == null || session.userId == null)
		return setTimeout(onConnect, 1000)
	var message = {"type": "user_connected", "room" : session.userId }
	socket.send(message)			
}

function onDisconnect(){console.log("onDisconnect")}

export default socket;
