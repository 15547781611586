import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import CallLine from "./CallLine"
import CallListDisplayHeader from './CallListDisplayHeader'
import CallListFilterBox from './CallListFilterBox'
import Pagination from '../../Pagination'
import Table from 'react-bootstrap/Table';

function CallsListDisplay(){
	const [count, setCount] = useState(null)
	const [displayFilterBox, setDisplayFilterBox] = useState(false)
	const [filterBoxQueryPayload, setFilterBoxQueryPayload] = useState('')
	const [pageNo, setPageNo] = useState(0)
	const [filter, setFilter] = useState('all')
	const [pageSize, setPageSize] = useState(25)		
	const [callLine, setCallLine] = useState("")
	const callListFilterBoxRef = useRef(null)
	const [ids, setIds] = useState([])
	let sess = JSON.parse(sessionStorage.getItem("ss_sess"))
	useEffect(() => {
		preDefinedFilterCalls(filter,pageNo)	
	},[])//use effect

function loadCalls(pn){
	axiosClient.getRequest(constants.CALL_URL+'?pageNo='+pn)
	.then(function (response) {
		let callList = null;
		callList = response.data.map((element, index) => (									
						<CallLine call= {element} />
		));
		
		if(Object.keys(callList).length === 0){
			setCount(0)
			setCallLine('No Records Found...')
		}else{
			setCallLine(callList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
			setQueryParam(filter, pn)
		}		
	}).catch(function (response) {
	    console.log(response);
	});
}

// function filterCalls(type){
// 	axiosClient.getRequest(constants.CALL_URL+"filter?type="+type)
// 		.then(function (response) {
// 		let callList = null;
// 		callList = response.data.map((element, index) => (					
// 						<CallLine call= {element} />
// 		));
// 		console.log(" callList "+ (callList))
// 		console.log("CallList "+ JSON.stringify(callList))
// 		setCallLine((Object.keys(callList).length === 0  ? (<tr><td></td><td>No Records Found...</td></tr>) : callList))
// 		}).catch(function (response) {
// 		    console.log(response);
// 		});	
// }

function handleFilterSubmit(payload,pn){
	console.log('handleFilterSubmit  '+JSON.stringify(payload))
	setPageNo(pn)
	setFilterBoxQueryPayload(payload)
	axiosClient.postRequest(constants.CALL_URL+'apply_filter?pageNo='+pn,payload)
	.then(function (response) {
		let callList = response.data.map((element, index) => (					
						<CallLine call= {element}/>					           
		));
		if(Object.keys(callList).length === 0){
			setCount(0)
			setCallLine('No Records Found...')
		}else{
			setCallLine(callList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
			setQueryParam(filter, pageNo)
		}
	}).catch(function (response) {
	    console.log(response);
	});  
}
function setQueryParam(type, number){
	console.log('setQueryParam ') 
	let queryParams = new URLSearchParams(window.location.search);
	queryParams.set('filter', type);
	queryParams.set('pageNo', number);
	window.history.replaceState(null, null, "?" + queryParams.toString());
}
function preDefinedFilterCalls(type,pn){
	setPageNo(pn)
	axiosClient.getRequest(constants.CALL_URL+"filter?type="+type+'&pageNo='+pn)
		.then(function (response) {
		let callList = null;
			callList = response.data.map((element, index) => (									
							<CallLine call= {element} />
			));
			if(Object.keys(callList).length === 0){
				setCount(0)
				setCallLine('No Records Found...')
			}else{
				setCallLine(callList)
				let countTemp = response.data[0].count;
				setCount(countTemp)
			}
			setFilter(type)
			setQueryParam(type, pn)			
			setFilterBoxQueryPayload('')
			setDisplayFilterBox(false)
	}).catch(function (response) {
		    console.log(response);
	});
}
function displayFilterBoxFun(){
	console.log('displayFilterBoxFun')
	setDisplayFilterBox(!displayFilterBox)
}
function closeFilterBox(){
	setDisplayFilterBox(false)
}
function handlePagination(number){
	console.log('handlePagination '+number+'. '+filterBoxQueryPayload+'. '+filter)
	setPageNo(number)
	// setQueryParam(filter, number)
	if(filterBoxQueryPayload === '' && filter === 'all')
		loadCalls(number)		
	else if(filterBoxQueryPayload === '' && filter !== 'all')
		preDefinedFilterCalls(filter,number)
	else
		handleFilterSubmit(filterBoxQueryPayload,number)	
}

return (
	<>
	<CallListDisplayHeader toggleFilterBox={displayFilterBox}
						   preDefinedFilterCalls={preDefinedFilterCalls}
						   displayFilterBoxFun={displayFilterBoxFun}/>
	<div class="main-body">
   		<div style={{display: 'flex', flexDirection:'row'}}>
   		{
		   	displayFilterBox ?
		   		<CallListFilterBox handleFilterSubmit={handleFilterSubmit} closeFilterBox={closeFilterBox}/>
		   		: ''
		}	   		
   		<div style={{width: '100%'}}>				
			<div class="container page-body" style={{padding:'20px',paddingTop:'10px', minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
         <div class="row">
            <div class="col-lg-12">
               <div class>
                  <div class="table-responsive">
                     <table class="table table-nowrap align-middle table-borderless">
                        <thead>
                           <tr>
                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Customer</th>
                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Number</th>
                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Agent</th>
                              <th scope="col" style={{minWidth:'175px',color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Virtual Number</th>
                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Direction</th>
							  <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Status</th>                              
							  <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Duration</th>                              
							  {(sess !== null && sess['role'].toUpperCase() === "agent".toUpperCase()) ? '' :    <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Cost</th> }
							  <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Time</th>                              
                           </tr>
                        </thead>
                        <tbody>
                        	{callLine}                    
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>         
      </div>
		   		<Pagination count={count} pageNo={pageNo}
				   					 handlePagination={handlePagination}/>
		   </div>	   	
		</div>	
	</div>
   </>
);

}
export default CallsListDisplay;
