import '../css/article.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useNavigate, useParams} from "react-router-dom"
import {useState, setState, useRef, useEffect} from 'react';

function Article(props){
  const categoryRef = useRef(null)
  useEffect(()=>{
    categoryRef.current.style.textDecoration = 'none'
  },[])
  
function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();   
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedDate = `${month} ${day}, ${year} at ${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  return formattedDate;
}
const handleLinkClick = (event) => {
    const target = event.target;
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);  // Create a URL object
    const domainName = `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}`;
    let link = target.href;
    console.log('link clicked '+target.href+'    '+domainName+'  '+link.includes(domainName))
    if (target.tagName === 'A' && target.href) {
      event.preventDefault(); // Prevent the default navigation
      if (link.includes(domainName)) {
        link = link.replace(domainName, '');
        if (link.startsWith('/')) {
          link = link.slice(1);
        }
      }
      window.open(link, '_blank'); // Open the link in a new tab
    }
};
return(

    <div class="container padding-30 ss-article workik-article-container">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-9 padding-20">
                <div class="row">
                    <Breadcrumb>
                          <Breadcrumb.Item href="#"><img src={require('../../../img/home.png')} alt='Home' style={{height:'25px', width:'25px'}}/></Breadcrumb.Item>
                          <Breadcrumb.Item active style={{color:'#3C67AA', textDecoration: 'none'}} ref={categoryRef}>
                                <span  style={{color:'#3C67AA', textDecoration: 'none'}}>{props.article.category.name}</span>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item active><a href="#" style={{color:'#3C67AA'}} onClick={props.displayArticlesAgain}>{props.article.subCategory.name}</a></Breadcrumb.Item>
                    </Breadcrumb>
                  
                    <div class="panel panel-default">
                        <div class="article-heading " >
                                <h2 style={{fontSize: '2rem', color: '#212529'}}>{props.article.description} </h2>
                        </div>
                        <div style={{marginBottom:'0.4rem', color:'rgba(33, 37, 41, 0.75)' }}>    
                                {formatDate(props.article.createdDate)} 
                        </div>
                        <div class="article-content">
                            <div dangerouslySetInnerHTML={{ __html: (decodeURIComponent(props.article.content).replaceAll('+',' ')) }}
                              onClick={handleLinkClick} />
                        </div>                                            
                    </div>
                </div>
            </div>    
        </div>
      </div>
   )

}export default Article