import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import TeamLine from "./TeamLine"
import TeamListDisplayHeader from './TeamListDisplayHeader'
import CreateTeamForm from './CreateTeamForm'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function TeamsListDisplay(){
	const [sessionData, setSessionData] = useState(null)
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(100)	
	const [teamLine, setTeamLine] = useState("")
	const editTeamRef = useRef(-1)
	const primaryCheckBoxRef = useRef(null)
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)	
	const [checkboxState, setCheckboxState] = useState(false)
	const [showCreateTeamForm, setShowCreateTeamForm] = useState(false)
	
	useEffect(() => {
		loadTeams();
		setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
		groupButtonsRef.current.style.visibility = 'hidden'
	},[])//use effect

function loadTeams(){
	axiosClient.getRequest(constants.TEAM_URL+'?pageNo='+pageNo+'&pageSize='+pageSize)
	.then(function (response) {
		let teamList = null;
		teamList = response.data.map((element, index) => (									
						<TeamLine team= {element} editTeam={editTeam} checkBoxClicked={checkBoxClicked}/>
		));
		setTeamLine((Object.keys(teamList).length === 0  ? 'No Records Found...' : teamList))
	}).catch(function (response) {
	    console.log(response);
	});	
}
	function selectMultipleTeams(){
		let elements = document.getElementsByClassName('team-checkbox')
		for (var i = 0; i < elements.length; i++) {
			elements[i].style.visibility = 'visible'
		}
	}

function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('team-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)
}

function checkBoxClicked(id, value){
	console.log('checkBoxClicked '+value)
	let idsTemp = ids;
	if(value){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.children[0].style.visibility = 'visible'
	}else{
		groupButtonsRef.current.children[0].style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}
}
	function createTeamGroup(){
		let teamsChecked = []
		let elements = document.getElementsByClassName('team-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				teamsChecked.push(elements[i].id)
		
		}

		axiosClient.getRequest(constants.TEAM_URL)
		.then(function (response) {
		let teamList = null;
		teamList = response.data.map((element, index) => (
						<TeamLine team= {element} editTeam={editTeam} checkBoxClicked={checkBoxClicked}/>					           
		));
		setTeamLine((Object.keys(teamList).length === 0  ? 'No Records Found...' : teamList))
		console.log("Team line set")
		}).catch(function (response) {
		    console.log(response);
		});		
	}

	function oncheckboxClicked(){
		setCheckboxState(!checkboxState)
	}

function searchTeams(event){
	let pageNo=0;
	let pageSize=10;	
	axiosClient.getRequest(constants.TEAM_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pageNo+'&pageSize='+pageSize)
	.then(function (response) {
	let teamList = null;
	teamList = response.data.map((element, index) => (					
					<TeamLine team= {element} index={index} editTeam={editTeam} checkBoxClicked={checkBoxClicked}/>					           
	));
	console.log("TeamList "+ JSON.stringify(teamList))
	setTeamLine((Object.keys(teamList).length === 0  ? 'No Records Found...' : teamList))
	}).catch(function (response) {
	    console.log(response);
	});	
}
function filterTeams(type){
	axiosClient.getRequest(constants.TEAM_URL+"filter?type="+type)
		.then(function (response) {
		let teamList = null;
		teamList = response.data.map((element, index) => (					
						<TeamLine team= {element} editTeam={editTeam} checkBoxClicked={checkBoxClicked}/>					           
		));
		console.log(" teamList "+ (teamList))
		console.log("TeamList "+ JSON.stringify(teamList))
		setTeamLine((Object.keys(teamList).length === 0  ? (<tr><td></td><td>No Records Found...</td></tr>) : teamList))
		}).catch(function (response) {
		    console.log(response);
		});	
}

function showCreateConactFormFun(){
	setShowCreateTeamForm(true)
}
function closeCreateWindow(){
	editTeamRef.current = -1
	setShowCreateTeamForm(false)
}
function performAction(type){
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.TEAM_URL+'delete';
	else if(type === 'block')
		url = constants.TEAM_URL+'block';
	else if(type === 'spam')
		url = constants.TEAM_URL+'spam';

	//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadTeams();
		let elements = document.getElementsByClassName('contact-list-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		} 
		toast.success("Teams Deleted Successfully")          
       }).catch((error) => {
           console.log(error)
       });
	groupButtonsRef.current.children[0].style.visibility = 'hidden' 
	primaryCheckBoxRef.current.checked = false          
}	
function editTeam(id){
	editTeamRef.current = id
	setShowCreateTeamForm(true)
}

	return (
	<>
		<TeamListDisplayHeader searchTeams={searchTeams} 
							   filterTeams={filterTeams}
							   performAction={performAction}
							   showCreateConactFormFun={showCreateConactFormFun}
							   groupButtonsRef={groupButtonsRef}/>
		<div class="main-body">
			<div class="container" style={{padding:'20px',paddingTop:'10px', minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
         <div class="row">
            <div class="col-lg-12">
               <div class>
                  <div class="table-responsive">
                     <table class="table table-nowrap align-middle table-borderless">
                        <thead>
                           <tr>
                              <th scope="col" class="ps-4" style={{width: "50px", color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>
                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" style={{border: '1px solid #A9A9A9', height:'15px'}} ref={primaryCheckBoxRef} onClick={onPrimaryCheckboxClicked}/></div>
                              </th>
                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800',width:'20%'}}>Name</th>
                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800',width:'70%'}}>Number of Agents</th>
                           </tr>
                        </thead>
                        <tbody>
                        	{teamLine}                    
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>         
      </div>

			{ showCreateTeamForm ? <CreateTeamForm closeCreateWindow={closeCreateWindow}
					editTeamRef={editTeamRef}/> : '' }
		</div>
	</>
 );

}
export default TeamsListDisplay;
