import React from "react"
import {useState, setState, useRef, useEffect, useContext} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import {Route, Link, Routes, useParams} from 'react-router-dom';
import LoadingAnimation from '../../LoadingAnimation'
import ChatMiddleWindow from './ChatMiddleWindow'
import ChatLeftWindow from './ChatLeftWindow'
import ChatUserMessage from './ChatUserMessage'
import ChatCustomerMessage from './ChatCustomerMessage'
import ReactDOMServer from 'react-dom/server';
import { io } from 'socket.io-client';
import '../css/chat.css'
import SocketContext from '../../SocketContext'


const chat_notification_styles = {
    notification: {
        position: 'fixed',
        top: '10px',
        right: '10px',
        padding: '15px',
        borderRadius: '8px',
        backgroundColor: '#e6eaeb',
        border: '1px solid #D3D3D3',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        maxWidth: '250px',
        textAlign: 'center',
        zIndex: '1000000 !important',
        width:'250px'
    },
    notification_p: {
      color:'black',
      fontWeight: '600',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    },
    acceptButton: {
        backgroundColor: 'lightgreen',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight:'600',
    },
    rejectButton: {
        backgroundColor: 'lightcoral',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight:'600',
    },
};



function ChatDisplay(props){
  let userId = "1";
  const middleBoxRef = useRef(null)
  const messageRef = useRef(null)
  const chatNotificationRef = useRef(null)
  const  [chatProfileData, setChatProfileData] = useState(-1)
  const  [clickedProfileId, setClickedProfileId] = useState(-1)
  const [chatProfileList, setChatProfileList] = useState([])
  const [middleBoxChatMessages, setMiddleBoxChatMessages] = useState([])
	const params = useParams();
	const navigate = useNavigate();
  const [chatData, setChatData] = useState(null)
	const commentsBodyRef = useRef(null)
  const contactBoxRef = useRef(null)
	const [ticket, setTicket] = useState(1)
  const [showNotification, setShowNotification] = useState(false);
  const [botificationMessage, setNotificationMessage] = useState(null)
	const socket = useContext(SocketContext);
  const [isShaking, setIsShaking] = useState(false);
  const [newConversationId, setNewConversationId] = useState(-1)
  const currentConversationIdRef = useRef(-1)
  const [chats, setChats] = useState([])
  const [contact, setContact] = useState(null)
  const [contactImage, setContactImage] = useState(null)
  const [agentImageUrl, setAgentImageUrl] = useState(null)
  const [name, setName] = useState('')
  const [status, setStatus] = useState('')

  useEffect(() => {
    console.log('Chat ChatDisplay  useEffect')
      socket.on('message', handleMessage);

      let profile = document.getElementsByClassName('topnav')
      profile[0].style.display = 'none';
      return () => {
        socket.off('message');
      };
  }, []);

useEffect(()=>{
      try {
            let session = JSON.parse(sessionStorage.getItem('ss_sess'))
            axiosClient.getRequest(constants.USER_URL+session.id)
            .then(function (response) {
                  setName(response.data.name)
                  setAgentImageUrl(response.data.image)
                  // setLoading(false);
            }).catch(function (response) {
                console.log(response);
            }); 
      } catch (error) {
          console.error("Error fetching chats:", error);
          // setLoading(false);
      }
}, [])
useEffect(() => {
    fetchChats();
  },[])

const fetchChats = async () => {
          try {
                axiosClient.getRequest(constants.CHAT_URL+'fetch_agent_chats')
                .then(function (response) {
                      setChats(response.data);
                      if(response.data.length > 1){
                          let conversationId = response.data[0].conversationId
                          axiosClient.getRequest(constants.CHAT_URL+"fetch_chats?id="+conversationId)
                          .then(function (response) {
                              currentConversationIdRef.current = conversationId               
                              setMiddleBoxChatMessages(response.data)        
                              let chat = chats.find((chat) => chat.conversationId === conversationId)       
                              setContact(chat.contactName)
                              setContactImage(chat.imageName)
                              setStatus(chat.status)
                          }).catch(function (response) {
                              console.log(response.data)
                          });
                      }
                }).catch(function (response) {
                    console.log(response);
                }); 
          } catch (error) {
              console.error("Error fetching chats:", error);
          }
};

function handleMessage(message){
  console.log('handleMessage '+message)
  message = typeof message === 'string' ? JSON.parse(message) : message;
  if(message.type === 'chat_initiated'){
    if (window.location.pathname !== '/chats') 
      return
    setNewConversationId(message.newConversationId)  
    setShowNotification(true)
    setTimeout(handleTimeout, 30000)   
  }else if (message.type === 'c_c_m') {
      console.log('handleMessage ' + JSON.stringify(message));
      const lastReadMessageKey = `lastReadMessageId_${message.conversationId}`;
      let storedLastReadMessageId;
      try {
          storedLastReadMessageId = sessionStorage.getItem(lastReadMessageKey);
      } catch (error) {
          console.warn('Session storage access failed:', error);
      }

      if (message.conversationId === currentConversationIdRef.current) {
          if (storedLastReadMessageId && parseInt(message.id) === parseInt(storedLastReadMessageId) + 1) {
              setMiddleBoxChatMessages((prevMessages) => [...prevMessages, message]);
              sessionStorage.setItem(lastReadMessageKey, message.id);
          }else{
            displayChatsInMiddleBox(message.conversationId)
          }
          
      } else
      {
          setChats(prevChats =>
          prevChats.map(chat =>
            chat.conversationId === message.conversationId
              ? { ...chat, content: message.content }
              : chat
          )
        );
      }      
  }
}



function closeContactBox(){
  contactBoxRef.current.style.display = 'none'
}

const handleTimeout = ()=>{

}

const handleChatAccept = () => {
  axiosClient.postRequest(constants.CHAT_URL+"accept_chat?id="+newConversationId)
          .then(function (response) {
          setShowNotification(false);
          setNewConversationId(-1) 
          fetchChats()         
  }).catch(function (response) {
              console.log(response.data)
  });
  
};

const handleChatReject = () => {
    setShowNotification(false);
};

function resolveConversation(conversationId){

}

function openConversation(conversationId){
  
}

function displayChatsInMiddleBox(conversationId){
    axiosClient.getRequest(constants.CHAT_URL+"fetch_chats?id="+conversationId)
    .then(function (response) {
        currentConversationIdRef.current = conversationId               
        setMiddleBoxChatMessages(response.data)        
        let chat = chats.find((chat) => chat.conversationId === conversationId)       
        setContact(chat.contactName)
        setContactImage(chat.imageName)
        setStatus(chat.status)
        if (response.data && response.data.length > 0) {
            const reversedMessages = [...response.data].reverse();

            const latestCustomerMessage = reversedMessages.find((message) => message.userId === null);
            const latestSentMessage = reversedMessages.find((message) => message.userId !== null);

            const messageState = {
                lastReadMessageId: latestCustomerMessage ? latestCustomerMessage.id : null,
                lastSentMessageId: latestSentMessage ? latestSentMessage.id : null,
            };

            const messageStateKey = `conversationState_${conversationId}`;
            sessionStorage.setItem(messageStateKey, JSON.stringify(messageState));
        }
    }).catch(function (response) {
        console.log(response.data)
    });
}


	return(
          <div class="chat-body">
              <div className="chat-window">
                  <ChatLeftWindow displayChatsInMiddleBox={displayChatsInMiddleBox} chats={chats} agentImageUrl={agentImageUrl} name={name}/>
                  <ChatMiddleWindow  middleBoxChatMessages={middleBoxChatMessages} currentConversationIdRef={currentConversationIdRef}
                        setMiddleBoxChatMessages={setMiddleBoxChatMessages} agentImageUrl={agentImageUrl}
                        contact={contact} contactImage={contactImage} status={status} resolveConversation={resolveConversation} openConversation={openConversation}/>
              </div>

          { showNotification &&
              (
                  <div style={chat_notification_styles.notification} className={isShaking ? 'shake' : ''} >
                      <p style={chat_notification_styles.notification_p}>
                          {botificationMessage || 'New Chat Request'}
                      </p>
                      <div style={chat_notification_styles.buttonContainer}>
                          <button 
                              style={chat_notification_styles.acceptButton} 
                              onClick={handleChatAccept}>
                              Accept
                          </button>
                          <button 
                              style={chat_notification_styles.rejectButton} 
                              onClick={handleChatReject}>
                              Reject
                          </button>
                      </div>
                  </div>
              )
          }
          </div> 
     
);
}

export default ChatDisplay;