import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import CreateContactGroupLine from './CreateContactGroupLine'
import { ToastContainer, toast } from 'react-toastify';

function CreateContactGroupForm(props){  
  const errorRef = useRef(null)
  const dropdownRef = useRef(null)
  const spinnerRef = useRef(null)
  const [dropdownLine, setDropdownLine] = useState([])
  const [ids, setIds] = useState([])
  const selectedContactsRef = useRef(null)
  const nameRef = useRef(null)

  const REPORTER_DROP_DOWN_INPUT_POS = 0;
  const REPORTER_DROP_DOWN_MENU_POS = 1;
  const REPORTER_DROP_DOWN_HIDDEN_INPUT_POS = 2;
  let editFilled = false;

  useEffect(() => {
    errorRef.current.style.display = 'none'
    spinnerRef.current.style.display = 'none'
    if(isEdit() && !editFilled){
      editFilled = true;
      fillEditForm(); 
    }    
  },[]);

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
function isEdit(){
  return props.editIdRef.current !== undefined && props.editIdRef.current !== -1
}
  function handleSubmit(event){  
    event.preventDefault()
    if(ids.length === 0){
      displayError('No Contact Selected')
      return
    }
    if(ids.length <= 1){
      displayError('More than one Contact required for a Group')
      return
    }

    spinnerRef.current.style.display = 'inline-block'
    let payload = {'name': event.target.name.value, 'ids':ids}
    if(!isEdit()){
        axiosClient.postRequest(constants.CONTACT_GROUP_URL, payload)
        .then(function (response) {    
          props.closeCreateWindow();
          window.location.reload()    
        }).catch(function (error) {
              if(error.response.status === 400){
                if(error.response.data.error === '')
                  errorRef.current.innerHTML = 'Error in processing the request'
                else
                  errorRef.current.innerHTML = error.response.data.error
              }else{
                errorRef.current.innerHTML = 'Error in processing the request'
              }
              errorRef.current.style.display = 'block'
          spinnerRef.current.style.display = 'none'    
        });
    }else{
        axiosClient.patchRequest(constants.CONTACT_GROUP_URL+props.editIdRef.current, payload)
        .then(function (response) {    
          props.closeCreateWindow();
          window.location.reload()    
        }).catch(function (error) {
            if(error.response.status === 400){
              if(error.response.data.error === '')
                errorRef.current.innerHTML = 'Error in processing the request'
              else
                errorRef.current.innerHTML = error.response.data.error
            }else{
              errorRef.current.innerHTML = 'Error in processing the request'
            }
            errorRef.current.style.display = 'block'
          });
          spinnerRef.current.style.display = 'none'
      }
  }

function fillEditForm(){
    axiosClient.getRequest(constants.CONTACT_GROUP_URL+props.editIdRef.current)
  .then(function (response) {
      nameRef.current.value = response.data.name
      for(var i=0;i<response.data.contacts.length; i++){
        let event = {'target':{'parentElement':{'innerHTML': ''}}}
        event['target']['name'] = response.data.contacts[i].id
        event['target']['innerHTML'] = response.data.contacts[i].name+';'+response.data.contacts[i].email
        onSelectContactDropdown(event,response.data.contacts[i].id,  response.data.contacts[i].name)
      }
      
  }).catch(function (error) {
    console.log(error)
      errorRef.current.innerHTML = error.response === undefined ? error : error.response.data.error
      errorRef.current.style.display = 'block'
  });
}
function searchContacts(event){
  event.target.parentElement.children[1].innerHTML = ''
  if(event.target.value.length < 3)
    return
  axiosClient.getRequest(constants.CONTACT_URL+"search_by_name?keyword="+event.target.value)
    .then(function (response) {
    response.data.map((element, index) => (
      addChildToContactDropdown(event, element)    
    ));
    }).catch(function (response) {
        console.log(response);
    });
}

  function onSelectContactDropdown(event, id, name){
    event.target.parentElement.innerHTML = ''
    let div = document.createElement('div')
    let input = document.createElement('input')
    input.style.display = 'none'
    input.value = id
    div.className = 'contact-group-selected-contact'
    let innerDivLeft = document.createElement('div')
    let innerDivRight = document.createElement('div')
    innerDivLeft.className = 'add-width-ellipsis'
    innerDivLeft.style.float = 'left'
    innerDivRight.style.float = 'right'
    innerDivLeft.innerHTML = name
    innerDivRight.innerHTML = '&times;'
    innerDivRight.onclick = removeSelectedContact
    innerDivRight.style.cursor = 'pointer'
    div.appendChild(input)
    div.appendChild(innerDivRight)
    div.appendChild(innerDivLeft)    
    selectedContactsRef.current.appendChild(div)
    let idsTemp = ids;
    idsTemp.push(id)
    setIds(idsTemp)
    dropdownRef.current.innerHTML = ''
    dropdownRef.current.style.display = 'none'
  }

  function removeSelectedContact(event){
    let children = selectedContactsRef.current.children  
    if(children.length === 0)
      return
    for(var i=0;i<children.length; i++){
      if(event.target.parentElement === children[i]){
        let id = children[i].children[0].value
        let idsTemp = ids;
        selectedContactsRef.current.removeChild(children[i])
        const index = idsTemp.indexOf(id);
         if (index > -1) {
              idsTemp.splice(index, 1);
          }
          setIds(idsTemp)            
        break
      }
    }
    console.log('ids after removal '+ids)    
  }


  function addChildToContactDropdown(event, element){
    console.log('addChildToContactDropdown '+ids+" "+element.id)
      if(ids.includes(element.id))
        return;
      let aTag = document.createElement('a')
      aTag.name=element.id
      let outerDiv = document.createElement('div')
      let innerLeftDiv = document.createElement('div')
      let innerRightDiv = document.createElement('div')
      innerLeftDiv.innerHTML = element.name
      innerRightDiv.innerHTML=element.email
      innerLeftDiv.style.float='left'
      innerLeftDiv.classList.add('add-ellipsis')
      innerRightDiv.classList.add('add-ellipsis')
      innerRightDiv.style.float='right'
      outerDiv.appendChild(innerRightDiv)
      outerDiv.appendChild(innerLeftDiv)
      aTag.appendChild(outerDiv)
      // aTag.onclick = onSelectDropdown()
      aTag.addEventListener('click', function(event){
          onSelectContactDropdown(event,element.id,element.name);
      });

      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].appendChild(aTag)
      dropdownRef.current.style.display = 'block'
  }


  return (
    <div class='create-wrapper'>
      <form onSubmit={handleSubmit}>          
          <div style={{ padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
              <a href="javascript:void(0)" class="create-closebtn" onClick={props.closeCreateWindow}>&times;</a>
              <div ref={errorRef} name="error"/>
              <h8 style={{color:'#044caf',fontWeight: '800', fontSize:'20px'}}><b>+ Create Contact Group</b> </h8>
              <div class='create-line'></div>
              <div class='create-form-label' style={{ marginTop: "3%"}}>Contact Group Name:<p class='red-color'>*</p></div>
              <div><input type="text" name="name" style={{width: "90%"}}  placeholder={'Name'} ref={nameRef} required /></div>
              <div class='create-form-label' style={{ marginTop: "3%"}}>Search Contacts For The Group:<p class='red-color'>*</p></div>
              <div style={{position: 'relative' }}>
                  <input type="text" name="contacts" style={{width: "90%", borderColor: '#909196'}}  placeholder={'Search Contacts'} onChange={searchContacts}/>
                  <div style={{position: 'absolute',display:'none'}} class='create-dropdown-content' ref={dropdownRef} />
              </div>              
              <div style = {{ marginTop: '100px'}} ref={selectedContactsRef} class='contact-group-selected-contacts-box' />        
          </div>    
          <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
            <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}}>{isEdit() ? 'Update Group' : 'Create Group'}</button>
            <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
          </div>          
        </form>
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>
    </div>

    );
}

export default CreateContactGroupForm;