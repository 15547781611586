import React, { useState, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import * as ConstantStrings from '../../../js/ConstantStrings'
import '../../../css/common.css'
import CustomRoleLine from "./CustomRoleLine"
import CustomRoleListDisplayHeader from './CustomRoleListDisplayHeader'
import Pagination from '../../Pagination'
import CreateCustomRoleForm from './CreateCustomRoleForm'
import { useLocation } from 'react-router-dom';
import {useNavigate, useParams} from "react-router-dom"
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CustomRolesListDisplay(){
	const [count, setCount] = useState(null)
	const editIdRef = useRef(-1)
	const [filter, setFilter] = useState('all')
	const [search, setSearch] = useState('')
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(25)
	const [customRoleLine, setCustomRoleLine] = useState("")
	const primaryCheckBoxRef = useRef(null)
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)
	const [showCreateCustomRoleForm, setShowCreateCustomRoleForm] = useState(false)
	const location = useLocation();
	const navigate = useNavigate(); 
	const params = useParams();
	const [sessionData, setSessionData] = useState(null)
	useEffect(() => {
		loadCustomRoles(pageNo, pageSize);
		groupButtonsRef.current.style.visibility = 'hidden'	
		setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
		if(params.id !== undefined){
			editIdRef.current = params.id
			setShowCreateCustomRoleForm(true)
		}
	},[])//use effect

function loadCustomRoles(pn,ps){
		axiosClient.getRequest(constants.CUSTOM_ROLE_URL)
		.then(function (response) {
		let customRoleList = null;
		customRoleList = response.data.map((element, index) => (									
						<CustomRoleLine customRole= {element} checkBoxClicked={checkBoxClicked} openCustomRole={openCustomRole}/>
		));		
		if(Object.keys(customRoleList).length === 0){
			setCount(0)
			setCustomRoleLine('No Records Found...')
		}else{
			setCustomRoleLine(customRoleList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
		}
	}).catch(function (error) {
	    console.log("Response" + error);
	});
}


function searchCustomRoles(event,pn){
	setSearch(event.target.value)
	setPageNo(pn)
	axiosClient.getRequest(constants.CUSTOM_ROLE_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pn)
	.then(function (response) {
		let customRoleList = null;
		customRoleList = response.data.map((element, index) => (					
						<CustomRoleLine customRole= {element} checkBoxClicked={checkBoxClicked} openCustomRole={openCustomRole}/>					           
		));
		if(Object.keys(customRoleList).length === 0){
			setCount(0)
			setCustomRoleLine('No Records Found...')
		}else{
			setCustomRoleLine(customRoleList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
		}	
	}).catch(function (response) {
	    console.log(response);
	});	
}



function showCreateCustomRoleFormFun(){
	setShowCreateCustomRoleForm(true)
}
function displayMessage(type){
	// let message;
	// if(type === 'delete')
	// 	message = ConstantStrings.DELETE_CUSTOM_ROLE_SUCCESS
	// else if(type === 'block')
	// 	message = ConstantStrings.BLOCK_CUSTOM_ROLE_SUCCESS
	// else if(type === 'spam')
	// 	message = ConstantStrings.SPAM_CUSTOM_ROLE_SUCCESS
	// toast.success(message)
}
function performAction(type){	
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.CUSTOM_ROLE_URL+'delete';
	else if(type === 'block')
		url = constants.CUSTOM_ROLE_URL+'block';
	else if(type === 'spam')
		url = constants.CUSTOM_ROLE_URL+'spam';

//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadCustomRoles(pageNo, pageSize);
		let elements = document.getElementsByClassName('customRole-line-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		}
			displayMessage(type)
       }).catch((error) => {
           console.log(error)
       }); 
       groupButtonsRef.current.style.visibility = 'hidden'     
}
function closeCreateWindow(load){
	setShowCreateCustomRoleForm(false)
	editIdRef.current = -1
	if(load)
		loadCustomRoles(0,25)
}

function displayIds(){
	console.log('displayIds')
}
function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('customRole-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}

function checkBoxClicked(id, value){
	let idsTemp = ids;
	if(value && !idsTemp.includes(id)){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}
	console.log('checkBoxClicked '+ idsTemp)
}


function openCustomRole(id){
	console.log('openCustomRole '+id)
	editIdRef.current = id
	setShowCreateCustomRoleForm(true)	
}
return (
		<>
			<CustomRoleListDisplayHeader 
									  showCreateCustomRoleFormFun={showCreateCustomRoleFormFun}
									  groupButtonsRef={groupButtonsRef}
									  performAction={performAction}/>
		
		<div class="main-body" >
		  <div class="page-bodyyy" >
				<div class="container" style={{padding:'20px',paddingTop:'10px', minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
		         <div class="row">
		            <div class="col-lg-12">
		               <div class>
		                  <div class="table-responsive">
		                     <table class="table table-nowrap align-middle table-borderless alternate-border-color" >
		                        <thead>
		                           <tr>
		                              <th style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}} scope="col" class="ps-4">
		                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" id="contacusercheck" ref={primaryCheckBoxRef} style={{border:'1px solid #A9A9A9'}} onClick={onPrimaryCheckboxClicked}/></div>
		                              </th>
		                              <th style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}} scope="col">Name</th>
		                              <th style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}} scope="col">Description</th>                              
		                              <th style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}} scope="col">Number of Users</th>                              
		                           </tr>
		                        </thead>
		                        <tbody>
		                        	{customRoleLine}                    
		                        </tbody>
		                     </table>
		                  </div>
		               </div>
		            </div>
		         </div>         
		      </div>				      	
		</div>				
			</div>
			{ showCreateCustomRoleForm ? <CreateCustomRoleForm closeCreateWindow= {closeCreateWindow} editIdRef={editIdRef}/> : '' }
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>			
		</>
 );

}
export default CustomRolesListDisplay;
