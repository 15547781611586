import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import CallRouteLine from "./CallRouteLine"
import CallRouteListDisplayHeader from './CallRouteListDisplayHeader'
import CreateCallRoute from './CreateCallRoute'
import {ToastContainer, toast } from 'react-toastify';

function CallRouteListDisply(){
	const primaryCheckBoxRef = useRef(null)
	const groupButtonsRef = useRef(null)
	const [ids, setIds] = useState([])
	const [callRouteLine, setCallRouteLine] = useState("No Records Found...")
	const [checkboxState, setCheckboxState] = useState(false)
	const [showCreateCallRouteForm, setShowCreateCallRouteForm] = useState(false)

	useEffect(() => {
		groupButtonsRef.current.style.visibility = 'hidden'	
		loadCallRoutes()
	},[])//use effect

	function loadCallRoutes(){
		axiosClient.getRequest(constants.CALL_URL+'call_route')
		.then(function (response) {
		let callRouteList = response.data.map((element, index) => (				
						<CallRouteLine callRoute= {element} checkBoxClicked={checkBoxClicked}/>
		));
		setCallRouteLine(callRouteList) 
		}).catch(function (response) {
		    console.log(response);
		});		
	}

	function createCallRouteGroup(){
		let callRoutesChecked = []
		let elements = document.getElementsByClassName('callRoute-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				callRoutesChecked.push(elements[i].id)
		
		}

		axiosClient.getRequest(constants.CONTACT_URL)
		.then(function (response) {
		let callRouteList = response.data.map((element, index) => (
						<CallRouteLine callRoute= {element} checkBoxClicked={checkBoxClicked}/>					           
		));
		setCallRouteLine(callRouteList) 
		}).catch(function (response) {
		    console.log(response);
		});		
	}

function searchCallRoutes(event){
	axiosClient.getRequest(constants.CALL_URL+"call_route/search?keyword="+event.target.value)
		.then(function (response) {
		let callRouteList = response.data.map((element, index) => (					
						<CallRouteLine callRoute= {element} checkBoxClicked={checkBoxClicked}/>					           
		));
		setCallRouteLine(callRouteList) 
		}).catch(function (response) {
		    console.log(response);
		});
}

function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('callroute-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}

function checkBoxClicked(id, value){
	let idsTemp = ids;
	if(value && !idsTemp.includes(id)){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}
}

function handleSubmit(event){
  event.preventDefault()

}

function setShowCreateCallRouteFormFun(){
	setShowCreateCallRouteForm(true)
}
function closeCreateWindow(){
	setShowCreateCallRouteForm(false)
}
function performAction(type){	
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.CALL_URL+'call_route/delete'
	

//TODO: block becomes unblock
   	axiosClient.patchRequest(url, payload)
       .then(function (response) {
       		loadCallRoutes()
			let elements = document.getElementsByClassName('callroute-line-checkbox')
			for (var i = 0; i < elements.length; i++) {
				if(elements[i].checked)
					elements[i].checked = false;		
			}
			toast.success('Call Route Deleted Successfully')      		
       }).catch((error) => {
           console.log(error)
       }); 
       groupButtonsRef.current.style.visibility = 'hidden'  
       primaryCheckBoxRef.current.checked=false        
}

	return (
	<>
		<CallRouteListDisplayHeader setShowCreateCallRouteFormFun = {setShowCreateCallRouteFormFun}
									groupButtonsRef={groupButtonsRef}
									performAction={performAction}
									searchCallRoutes={searchCallRoutes}/>
			<div class="main-body">
				<div class="container" style={{padding:'20px',paddingTop:'10px', minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
		         <div class="row">
		            <div class="col-lg-12">
		               <div class>
		                  <div class="table-responsive">
		                     <table class="table table-nowrap align-middle table-borderless">
		                        <thead>
		                           <tr>
		                              <th scope="col" class="ps-4" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>
		                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" id="contacusercheck" ref={primaryCheckBoxRef} style={{border:'1px solid #A9A9A9'}} onClick={onPrimaryCheckboxClicked} ref={primaryCheckBoxRef}/></div>
		                              </th>
		                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Name</th>
		                           </tr>
		                        </thead>
		                        <tbody>
		                        	{callRouteLine}                    
		                        </tbody>
		                     </table>
		                  </div>
		               </div>
		            </div>
		         </div>         
		      </div>
			</div>
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>						
	</>
 );

}
export default CallRouteListDisply;
